import { Namespace } from "@src/Generated/permissions/namespace";
import { Permit } from "@src/Generated/permissions/permit";

import { usePermissionCheck } from "../permissionsCheck";

export function useCanViewTroubleshooting() {
  return usePermissionCheck({
    objectType: Namespace.ObjectPolicy,
    objectId: TROUBLESHOOTING_OBJECT_POLICY_ID,
    relation: Permit.Read
  });
}

export function useCanCreateTroubleshooting(orgId: string | null) {
  return usePermissionCheck({
    objectType: Namespace.Org,
    objectId: orgId,
    relation: Permit.CreateTroubleshooting
  });
}

export function useCanEditTroubleshooting(id: string | null) {
  return usePermissionCheck({
    objectType: Namespace.Troubleshooting,
    objectId: id,
    relation: Permit.Write
  });
}

export function useCanDeleteTroubleshooting(id: string | null) {
  return usePermissionCheck({
    objectType: Namespace.Troubleshooting,
    objectId: id,
    relation: Permit.Delete
  });
}
