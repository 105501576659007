import styled from "styled-components";

const CheckboxLabel = styled.label<{ $disabled?: boolean }>`
  display: flex;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  align-items: baseline;
`;

const CheckboxInput = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;

  & + span {
    position: relative;
    width: 24px;
    height: 12px;
    margin-top: 3px;
    display: block;
    background-color: #77869c;
    border-radius: 6px;
    transition: background 0.2s ease;

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 12px;
      height: 12px;
      display: block;
      background-color: #2e3789;
      border-radius: 10px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      transition: all 0.2s ease;
    }

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 32px;
      height: 32px;
      background: rgba(79, 46, 220, 0.5);
      border-radius: 50%;
      transform: scale(0);
      opacity: 1;
      pointer-events: none;
      top: -12px;
    }
  }

  &:checked + span {
    background: rgba(154, 92, 150, 0.6);

    &::before {
      background-color: #662099;
      transform: translateX(12px);
      transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
    }

    &::after {
      transform: scale(1);
      opacity: 0;
      transition: all 0.4s ease;
    }
  }

  &:disabled {
    & + span {
      background-color: ${({ theme }) => theme.grey};

      &::before {
        background-color: ${({ theme }) => theme.darkGrey};
      }

      &::after {
        background: rgba(125, 125, 125, 0.5);
      }
    }

    &:checked + span {
      background: rgba(204, 204, 204, 0.6);
      &::before {
        background-color: ${({ theme }) => theme.darkGrey};
      }
    }
  }
`;
export const CheckBoxWrapper = styled.div`
  display: flex;
  gap: 6px;
  padding-bottom: 10px;
  vertical-align: baseline;
`;

interface CheckboxSliderProps {
  name: string;
  checked: boolean;
  disabled?: boolean;
  onChange?: () => void;
  labelLeft?: string;
  labelRight?: string;
  title?: string;
}

export function CheckboxSlider({ labelLeft, labelRight, title, ...props }: CheckboxSliderProps) {
  return (
    <>
      {labelLeft}
      <CheckboxLabel title={title} $disabled={props?.disabled}>
        <CheckboxInput {...props} readOnly={!props.onChange} />
        <span />
      </CheckboxLabel>
      {labelRight}
    </>
  );
}
