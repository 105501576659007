import { FieldArray, FormikProps } from "formik";
import styled from "styled-components";

import { Input } from "@src/Components/Input/Input";
import {
  FieldLabel,
  Fieldset,
  InputErrors,
  InputGroup,
  PaddedError,
  TextAreaField
} from "@src/Components/Input/InputGroup";
import { SelectedInputTags } from "@src/Components/Input/SelectedInputTags";
import { H3 } from "@src/Components/Text";
import { Tag } from "@src/Generated/graphql";

import { BlockPublisherForm } from "../serialise";

type ChartYamlFormProps = FormikProps<BlockPublisherForm>;

const IconPreview = styled.img`
  grid-column: 2;
  margin-bottom: 13px;
  max-width: 100%;
  max-height: 120px;
`;

export function ChartYamlForm({ ...formikProps }: ChartYamlFormProps) {
  const { values, errors } = formikProps;

  return (
    <div>
      <H3>Descriptor Values</H3>
      <Fieldset>
        <InputGroup name="descriptor.name" label="name" errors={errors} />
        <InputGroup name="descriptor.version" label="version" errors={errors} />
        <InputGroup name="descriptor.displayName" label="display name" errors={errors} />
        <InputGroup name="descriptor.vendor" label="vendor" errors={errors} />
        <FieldLabel htmlFor="descriptor.description">description</FieldLabel>
        <TextAreaField name="descriptor.description" errors={errors} />
        <InputGroup name="descriptor.iconUrl" label="icon Url" errors={errors} />
        <IconPreview src={values.descriptor.iconUrl} alt="icon preview" />
        <FieldLabel>categories</FieldLabel>
        <FieldArray name="descriptor.categories">
          {({ push, remove }) => (
            <div>
              <SelectedInputTags
                tags={(values.descriptor?.categories || []).map(c => ({ key: c, inc: true }))}
                remove={remove}
                getTagKey={(tag: Tag) => tag.key}
                renderTagContent={(tag: Tag) => tag.key}
              />
              <Input
                onKeyUp={e => {
                  const input = e.target as HTMLInputElement;
                  if (e.key === "Enter" && input.value) {
                    e.preventDefault();
                    push(input.value);
                    input.value = "";
                  }
                }}
              />
              <PaddedError>
                <InputErrors errors={errors} name="descriptor.categories" />
              </PaddedError>
            </div>
          )}
        </FieldArray>
      </Fieldset>
    </div>
  );
}
