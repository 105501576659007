import { ReactNode, useEffect } from "react";

interface DelayedRedirectProps {
  delay: number;
  to: string;
}

export function DelayedRedirect({ delay, to }: DelayedRedirectProps): ReactNode {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.location.href = to;
    }, delay);
    return () => clearTimeout(timeoutId);
  });

  return null;
}
