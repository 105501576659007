import { ReactNode } from "react";
import styled from "styled-components";

import { H1 } from "@src/Components/Text";

export const NoResourceMessage = styled.p`
  font-weight: 500;
  font-size: 20px;
  color: ${({ theme }) => theme.darkGrey};
`;

export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
`;

export const NotFoundMessage = styled.p`
  font-size: 14px;
  & span {
    text-transform: capitalize;
  }
`;

interface NotFoundProps {
  entity?: string;
  children?: ReactNode;
}

export function NotFound({ entity, children }: NotFoundProps) {
  return (
    <NotFoundWrapper>
      <H1>Oops! Not found</H1>
      <NotFoundMessage>
        The <span>{entity ? entity : "resource"}</span> you are looking for does not exist or you do
        not have the permissions to see it.
      </NotFoundMessage>
      {children}
    </NotFoundWrapper>
  );
}
