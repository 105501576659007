import { EditorStatus } from "@src/Components/EmbeddedEditor/editorStatus";
import { PublisherEditorAction } from "@src/Components/EmbeddedEditor/Publisher/publisherReducerTypes";

export interface PublisherEditorState {
  values: {
    current: string;
    original: string;
    saved: string;
    status: EditorStatus;
  };
  overrides: {
    current: string;
    original: string;
    saved: string;
    status: EditorStatus;
  };
}

export const initialState: PublisherEditorState = {
  values: {
    current: "some initial values state",
    original: "some initial values state",
    saved: "some initial values state",
    status: EditorStatus.Default
  },
  overrides: {
    current: "some initial overrides state",
    original: "some initial overrides state",
    saved: "some initial overrides state",
    status: EditorStatus.Default
  }
};

export function editorPublisherReducer(
  state = initialState,
  action: PublisherEditorAction
): PublisherEditorState {
  if (!action) return state;
  const { type, payload } = action;

  switch (type) {
    case "init":
      return {
        values: {
          ...state.values,
          original: payload.values,
          status:
            payload.values === state.values.current ? EditorStatus.Default : EditorStatus.Saved
        },
        overrides: {
          ...state.overrides,
          original: payload.overrides,
          status:
            payload.overrides === state.overrides.current
              ? EditorStatus.Default
              : EditorStatus.Saved
        }
      };
    case "change":
      const changeStatus =
        payload.value === state[payload.chart].original
          ? EditorStatus.Default
          : payload.value === state[payload.chart].saved
          ? EditorStatus.Saved
          : EditorStatus.Unsaved;
      return {
        ...state,
        [payload.chart]: {
          ...state[payload.chart],
          status: changeStatus,
          current: payload.value
        }
      };
    case "unlock":
      return {
        ...state,
        [payload.chart]: {
          ...state[payload.chart],
          saved: payload.value
        }
      };
    case "cancel":
      return {
        ...state,
        [payload.chart]: {
          ...state[payload.chart],
          current: state[payload.chart].saved,
          status:
            state[payload.chart].saved === state[payload.chart].original
              ? EditorStatus.Default
              : EditorStatus.Saved
        }
      };
    case "save":
      return {
        ...state,
        [payload.chart]: {
          ...state[payload.chart],
          status:
            state[payload.chart].current === state[payload.chart].original
              ? EditorStatus.Default
              : EditorStatus.Saved,
          saved: state[payload.chart].current
        }
      };
    case "loadOriginal":
      return {
        ...state,
        [payload.chart]: {
          ...state[payload.chart],
          status: EditorStatus.Default,
          saved: state[payload.chart].original,
          current: state[payload.chart].original
        }
      };
    case "changeVersion":
      return {
        values: {
          current: payload.values,
          original: payload.values,
          saved: payload.values,
          status: EditorStatus.Default
        },
        overrides: {
          current: payload.overrides,
          original: payload.overrides,
          saved: payload.overrides,
          status: EditorStatus.Default
        }
      };
  }
  return state;
}
