import { editor, IRange } from "monaco-editor";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useQuery } from "@apollo/client";
import { useMonaco } from "@monaco-editor/react";
import FetchCompletions from "@src/AdminDashboard/Completions/FetchCompletions.graphql";
import { FetchCompletionsQuery } from "@src/Generated/graphql";
import { notEmpty } from "@src/Utils/filterEmpty";

function searchRegex(comment: string) {
  return `(\\w+:|-)(.*)(#${comment})`;
}

// YAML key which triggers the site ID Code Lens
const magicSiteLabel = "label:";
const magicSiteComment = "nearbyone_site";

export function useSiteIdCompletion(
  editor: editor.IStandaloneDiffEditor | editor.IStandaloneCodeEditor | null
) {
  const { data, loading } = useQuery<FetchCompletionsQuery>(FetchCompletions);

  let completions: { [key: string]: string[] } = useMemo(() => ({}), []);
  if (data?.customCompletions) {
    const json = data.customCompletions;
    try {
      completions = JSON.parse(json);
    } catch {}
  }

  const [range, setRange] = useState<IRange | null>(null);
  const hideModal = useCallback(() => setRange(null), []);

  const monaco = useMonaco();
  useEffect(() => {
    if (!monaco || !editor || loading) return;
    const completionsProvider = monaco.languages.registerCompletionItemProvider("yaml", {
      provideCompletionItems: function (model, position) {
        const magicComment = model.getLineContent(position.lineNumber).match(/.*#(\w+)/)?.[1];
        if (!magicComment) {
          return {
            suggestions: []
          };
        }
        const word = model.getWordUntilPosition(position);
        const range = {
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: word.startColumn,
          endColumn: word.endColumn
        };
        return {
          suggestions: (completions[magicComment] || []).map(c => ({
            label: c,
            insertText: c,
            kind: monaco.languages.CompletionItemKind.Function,
            range
          }))
        };
      }
    });

    const setSiteRange = editor.addCommand(
      monaco.KeyCode.Unknown,
      function (accessor, range: IRange) {
        setTimeout(() => setRange(range));
      }
    );

    const suggest = editor.addCommand(monaco.KeyCode.Unknown, function (accessor, range: IRange) {
      const singleEditOperation = {
        text: "  ",
        range
      };
      if (isDiffEditor(editor)) {
        editor?.getModel?.()?.modified.applyEdits([singleEditOperation]);
      } else {
        editor.executeEdits("", [singleEditOperation]);
      }

      editor.setSelection({
        ...range,
        startColumn: range.startColumn + 1,
        endColumn: range.startColumn + 1
      });
      editor.trigger("", "editor.action.triggerSuggest", {});
    });

    const codeLens = monaco.languages.registerCodeLensProvider("yaml", {
      provideCodeLenses(model) {
        const siteCommentMatches = model.findMatches(
          searchRegex(magicSiteComment),
          false,
          true,
          true,
          " ",
          true
        );

        const siteLabelMatches = model.findMatches(magicSiteLabel, false, false, true, " ", false);

        return {
          lenses: Object.keys(completions).reduce(
            (lenses, magicComment) => {
              const matches = model.findMatches(
                searchRegex(magicComment),
                false,
                true,
                true,
                " ",
                true
              );
              return lenses.concat(
                matches
                  .map(({ range, matches }) => {
                    if (!matches) return null;
                    const insertRange: IRange = {
                      ...range,
                      startColumn: range.startColumn + matches[1].length,
                      endColumn: range.endColumn - matches[3].length
                    };
                    return {
                      range,
                      command: {
                        id: suggest as string,
                        title: "Show completions",
                        arguments: [insertRange],
                        tooltip: ""
                      }
                    };
                  })
                  .filter(notEmpty)
              );
            },
            siteCommentMatches
              .map(({ range, matches }) => {
                if (!matches) return null;
                const insertRange: IRange = {
                  ...range,
                  startColumn: range.startColumn + matches[1].length,
                  endColumn: range.endColumn - matches[3].length - 1
                };
                return {
                  range,
                  command: {
                    id: setSiteRange as string,
                    title: "Select site",
                    arguments: [insertRange],
                    tooltip: "insert site ID"
                  }
                };
              })
              .filter(notEmpty)
              .concat(
                siteLabelMatches.map(({ range }) => {
                  const insertRange: IRange = {
                    ...range,
                    startColumn: range.endColumn,
                    endColumn: model.getLineLength(range.startLineNumber) + 1
                  };
                  return {
                    range,
                    command: {
                      id: setSiteRange as string,
                      title: "Select site",
                      arguments: [insertRange],
                      tooltip: "insert site ID"
                    }
                  };
                })
              )
          ),
          dispose: () => null
        };
      }
    });

    return () => {
      completionsProvider.dispose();
      codeLens.dispose();
    };
  }, [completions, editor, loading, monaco]);

  const insertSite = useCallback(
    (siteId: string) => {
      if (!range || !editor) return;
      const singleEditOperation = {
        forceMoveMarkers: true,
        text: " " + siteId,
        range
      };
      if (isDiffEditor(editor)) {
        editor.getModel()?.modified.applyEdits([singleEditOperation]);
      } else {
        console.log(editor.getEditorType());
        editor.executeEdits("", [singleEditOperation]);
      }
      setRange(null);
    },
    [editor, range]
  );

  return {
    modalIsShown: !!range,
    hideModal,
    insertSite
  };
}

function isDiffEditor(
  editor: editor.IStandaloneDiffEditor | editor.IStandaloneCodeEditor
): editor is editor.IStandaloneDiffEditor {
  return (editor as editor.IStandaloneDiffEditor).getEditorType() === "vs.editor.IDiffEditor";
}
