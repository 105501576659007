import { useEffect, useState } from "react";

async function fetchUrl<T>(
  url: string,
  setData: (d: T) => void,
  setLoading: (loading: boolean) => void,
  setError: (loading: boolean) => void
) {
  try {
    const response = await fetch(url);
    setData(await response.json());
  } catch {
    setError(true);
  }
  setLoading(false);
}

export function useFetch<T>(url: string): [T | undefined, boolean, boolean] {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchUrl(url, setData, setLoading, setError);
  }, [url]);

  return [data, loading, error];
}
