// Code generated by jtd-codegen for TypeScript v0.2.1

export enum Permit {
  Any = "any",
  Create = "create",
  CreateBlockChart = "createBlockChart",
  CreateChain = "createChain",
  CreateCloudResource = "createCloudResource",
  CreateCloudlet = "createCloudlet",
  CreateComputeResource = "createComputeResource",
  CreateConfigurationManagement = "createConfigurationManagement",
  CreateCore = "createCore",
  CreateCoreManager = "createCoreManager",
  CreateFederationGuest = "createFederationGuest",
  CreateFederationHost = "createFederationHost",
  CreateGlobalGroup = "createGlobalGroup",
  CreateGnB = "createGnB",
  CreateIppool = "createIPPool",
  CreateIppoolMapping = "createIPPoolMapping",
  CreateMecChain = "createMecChain",
  CreateMecSite = "createMecSite",
  CreateNetwork = "createNetwork",
  CreateOrg = "createOrg",
  CreatePassiveDevice = "createPassiveDevice",
  CreateProvision = "createProvision",
  CreateRanManager = "createRanManager",
  CreateSim = "createSIM",
  CreateSite = "createSite",
  CreateSubnet = "createSubnet",
  CreateSubnetMapping = "createSubnetMapping",
  CreateTroubleshooting = "createTroubleshooting",
  CreateUser = "createUser",
  Delete = "delete",
  DeleteConfigurationManagement = "deleteConfigurationManagement",
  DeleteSim = "deleteSIM",
  Dismiss = "dismiss",
  DismissUser = "dismissUser",
  Invite = "invite",
  InviteGlobalGroupUser = "inviteGlobalGroupUser",
  InviteUser = "inviteUser",
  Read = "read",
  ReadAll = "readAll",
  ReadConfigurationManagement = "readConfigurationManagement",
  ReadSim = "readSIM",
  Recover = "recover",
  RemoveIppoolMapping = "removeIPPoolMapping",
  RemoveSubnetMapping = "removeSubnetMapping",
  View = "view",
  ViewFederation = "viewFederation",
  Write = "write",
  WriteConfigurationManagement = "writeConfigurationManagement",
  WriteSim = "writeSIM",
}
