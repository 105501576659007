import "./resizable.css";

import { ReactNode, useCallback } from "react";
import { ResizableBox, ResizeCallbackData } from "react-resizable";
import { CSSTransition } from "react-transition-group";
import styled, { CSSProperties } from "styled-components";
import { useWindowSize } from "usehooks-ts";

import { panelHeightKey } from "@src/LogsPanel/panelHeightKey";

import { Clickable } from "../Buttons/Clickable";
import { H3 } from "../Text";
import { panelTopZIndex } from "../zIndexValues";

export const PanelWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  box-sizing: border-box;
  overflow: hidden;
`;

export const PanelTopWrapper = styled.div`
  position: sticky;
  top: 0;
  border-bottom: 1px solid #ccc;
  z-index: ${panelTopZIndex};
`;

export const PanelTitleActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px 10px 40px;
  box-sizing: border-box;
  background-color: white;
  border-bottom: 1px solid #ccc;
`;

export const Cross = styled(Clickable).attrs({ title: "close-panel" })`
  font-size: 20px;
`;

export const PanelTitle = styled(H3)`
  margin: 0;
`;

const resizableBoxStyles: CSSProperties = {
  backgroundColor: "white",
  position: "sticky",
  bottom: 0,
  boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.25)"
};

const transitionName = "panelUp";

const Animation = styled.div`
  &.${transitionName}-enter {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  &.${transitionName}-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 200ms, transform 300ms ease-out;
  }
  &.${transitionName}-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.${transitionName}-exit-active {
    opacity: 1;
    transform: translate3d(0, 100%, 0);
    transition: opacity 200ms, transform 300ms ease-in;
  }
`;

interface BottomPanelProps {
  show: boolean;
  closePanel: () => void;
  children: ReactNode;
}

export function BottomPanel({ show, closePanel, children }: BottomPanelProps) {
  const handleResize = useCallback(
    (e: never, data: ResizeCallbackData) => {
      const height = data.size.height;
      if (height < 200) closePanel();
    },
    [closePanel]
  );

  const saveHeightValue = useCallback((e: never, data: ResizeCallbackData) => {
    const height = data.size.height;
    localStorage.setItem(panelHeightKey, JSON.stringify(height));
  }, []);

  const { height: windowHeight } = useWindowSize();
  const panelHeight = JSON.parse(localStorage.getItem(panelHeightKey) ?? "350");

  return (
    <CSSTransition in={show} timeout={200} appear={true} unmountOnExit classNames={transitionName}>
      <Animation>
        <ResizableBox
          width={Infinity}
          height={panelHeight <= windowHeight ? panelHeight : windowHeight}
          resizeHandles={["nw"]}
          axis="y"
          style={resizableBoxStyles}
          onResize={handleResize}
          onResizeStop={saveHeightValue}
        >
          {children}
        </ResizableBox>
      </Animation>
    </CSSTransition>
  );
}
