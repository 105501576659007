/* eslint-disable @typescript-eslint/triple-slash-reference */
/// <reference path="./defines.d.ts" />
/// <reference path="./imports.d.ts" />
/// <reference path="./Generated/graphql.d.ts" />

import "mapbox-gl/src/css/mapbox-gl.css";
import "./monaco";

import { ReactNode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { ApolloProvider } from "@apollo/client";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { getApolloClient } from "@src/Clients/Apollo/apolloClient";
import { queryClient } from "@src/Clients/ReactQuery/reactQueryClient";
import { GlobalStyles } from "@src/Components/GlobalStyles";
import { muiTheme, theme } from "@src/Components/theme";
import { SvgDefTrackerProvider } from "@src/Hooks/svgDef";

import { App } from "./App";
import { LoadingPage } from "./Components/Loading/LoadingPage";
import { AppErrorFallBack, appFallbackMsg, appFallbackTitle } from "./Error/AppErrorFallback";
import { SessionProvider } from "./Session";

const rootEl = document.getElementById("root") as Element;
export const root = createRoot(rootEl);

const orglessApolloClient = getApolloClient(null);

function Providers({ children }: { children: ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyles />
      <ErrorBoundary fallback={<AppErrorFallBack title={appFallbackTitle} msg={appFallbackMsg} />}>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={muiTheme}>
            <SvgDefTrackerProvider>
              <BrowserRouter>
                <SessionProvider>
                  <ApolloProvider client={orglessApolloClient}>
                    <Suspense fallback={<LoadingPage />}>{children}</Suspense>
                  </ApolloProvider>
                </SessionProvider>
              </BrowserRouter>
            </SvgDefTrackerProvider>
            {REACT_QUERY_SHOW_DEVTOOLS && <ReactQueryDevtools initialIsOpen={false} />}
          </MuiThemeProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

document.addEventListener("DOMContentLoaded", () =>
  root.render(
    <Providers>
      <App />
    </Providers>
  )
);

if (module.hot) {
  module.hot.accept("./App", () => {
    /* eslint-disable @typescript-eslint/no-var-requires */
    const { App: NewApp } = require("./App");

    root.render(
      <Providers>
        <NewApp />
      </Providers>
    );
  });
}
