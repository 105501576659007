import { createContext, ReactNode, useCallback, useContext, useEffect, useReducer } from "react";
import styled from "styled-components";

export interface SvgDefTrackerState {
  [elementId: string]: { instanceId: string; count: number };
}

export const initialState: SvgDefTrackerState = {};

export interface RegisterAction {
  type: "register";
  elementId: string;
  instanceId: string;
}

export interface UnregisterAction {
  type: "unregister";
  elementId: string;
}

export function reducer(
  state: SvgDefTrackerState,
  action: RegisterAction | UnregisterAction
): SvgDefTrackerState {
  switch (action.type) {
    case "register":
      return {
        ...state,
        [action.elementId]: {
          count: (state[action.elementId] ? state[action.elementId].count : 0) + 1,
          instanceId: action.instanceId
        }
      };
    case "unregister":
      const { [action.elementId]: registered, ...rest } = state;
      if (!registered) return state;
      if (registered.count > 1) {
        return {
          ...rest,
          [action.elementId]: {
            ...state[action.elementId],
            count: state[action.elementId].count - 1
          }
        };
      }
      return {
        ...rest
      };
  }
}

export interface SvgDefTracker {
  register: (elementId: string, instanceId: string) => void;
  unregister: (elementId: string) => void;
  state: SvgDefTrackerState;
}

export const SVG = styled.svg`
  position: absolute;
  top: 0;
  z-index: -1;
  visibility: hidden;
`;

export function useSvgDefTracker(): SvgDefTracker {
  const [state, dispatch] = useReducer(reducer, initialState);

  const register = useCallback((elementId: string, instanceId: string) => {
    dispatch({
      type: "register",
      elementId,
      instanceId
    });
  }, []);

  const unregister = useCallback((elementId: string) => {
    dispatch({
      type: "unregister",
      elementId
    });
  }, []);

  return { state, register, unregister };
}

export const SvgDefTrackerContext = createContext<SvgDefTracker | null>(null);

interface SvgDefTrackerProviderProps {
  children: ReactNode;
}

export const SvgDefTrackerProvider = ({ children }: SvgDefTrackerProviderProps) => (
  <SvgDefTrackerContext.Provider value={useSvgDefTracker()}>
    {children}
  </SvgDefTrackerContext.Provider>
);

export const useSvgDef = (elementId: string, instanceId: string) => {
  const tracker = useContext(SvgDefTrackerContext);
  const { register, unregister } = tracker || {};

  useEffect(() => {
    if (!register || !unregister) return;
    register(elementId, instanceId);
    return () => unregister(elementId);
  }, [elementId, instanceId, register, unregister]);
};
