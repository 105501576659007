import { useCallback, useReducer } from "react";
import styled from "styled-components";

import { useMutation } from "@apollo/client";
import { TabContentFullPadding } from "@src/Components/tabContent";
import { H1, MapOverlayText } from "@src/Components/Text";
import { LocationSearch } from "@src/Map/LocationSearch";
import { initialMapState, mapReducer } from "@src/Map/mapReducer";
import { useMapSettings } from "@src/Map/mapState";

import { SetMapMutation, SetMapMutationVariables } from "../../Generated/graphql";
import { MapboxMap, MapButton } from "../../Map/MapboxMap";
import { DeviceMarkers } from "./DeviceMarkers";
import SetMap from "./Map.graphql";
import MapSettings from "./Settings.graphql";

export const Instructions = styled(MapOverlayText)`
  font-size: 2em;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.4;
`;

const SetPosition = styled(MapButton)`
  order: 1;
`;

const TopLine = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
`;

function MapSettingsComponent() {
  const [mapState, dispatch] = useReducer(mapReducer, initialMapState);
  const { longitude, latitude, zoom } = mapState;
  const mapIsReady = useMapSettings(mapState, dispatch);

  const [setMapMutation] = useMutation<SetMapMutation, SetMapMutationVariables>(SetMap);
  const setMap = useCallback(() => {
    setMapMutation({
      variables: {
        center: { lat: latitude as number, lng: longitude as number },
        zoom: Math.round(zoom as number)
      },
      refetchQueries: [{ query: MapSettings }]
    });
  }, [latitude, longitude, zoom, setMapMutation]);

  return (
    <>
      <H1>Map</H1>
      <TabContentFullPadding>
        <TopLine>
          <SetPosition disabled={!mapIsReady} onClick={setMap}>
            set position
          </SetPosition>
          <LocationSearch mapState={mapState} dispatch={dispatch} />
        </TopLine>
        <MapboxMap mapState={mapState} dispatch={dispatch}>
          <Instructions>Drag/zoom the map to set the default map position</Instructions>
          <DeviceMarkers mapState={mapState} />
        </MapboxMap>
      </TabContentFullPadding>
    </>
  );
}

export default MapSettingsComponent;
