import { Namespace } from "@src/Generated/permissions/namespace";
import { Permit } from "@src/Generated/permissions/permit";

import { usePermissionCheck } from "../permissionsCheck";

export function useCanReadProvision() {
  return usePermissionCheck({
    objectType: Namespace.ObjectPolicy,
    objectId: PROVISION_OBJECT_POLICY_ID,
    relation: Permit.Read
  });
}

export function useCanCreateProvision(orgId: string | null) {
  return usePermissionCheck({
    objectType: Namespace.Org,
    objectId: orgId,
    relation: Permit.CreateProvision
  });
}

export function useCanEditProvision(id: string | null) {
  return usePermissionCheck({
    objectType: Namespace.Provision,
    objectId: id,
    relation: Permit.Write
  });
}
