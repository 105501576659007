import { LatLng } from "@src/Generated/graphql";

interface DropdownItem {
  id: string;
  index?: number;
  displayName: string;
  disabled?: boolean;
  message?: string;
}

export interface PlaceDropdownItem extends DropdownItem {
  type: "place";
}

export interface CoordsDropdownItem extends DropdownItem {
  type: "coords";
  coords: LatLng;
}

export interface DeviceDropdownItem extends DropdownItem {
  type: "device";
  coords: LatLng;
}

export type LocationDropdownItem = PlaceDropdownItem | CoordsDropdownItem | DeviceDropdownItem;

export interface Section {
  section: string;
  data: LocationDropdownItem[];
}

export interface LocationState {
  coords: LatLng | null;
  devices: DeviceDropdownItem[];
  places: PlaceDropdownItem[];
}

function tryParseCoords(input: string): LatLng | null {
  if (!/\S.*,.*\S/.test(input)) return null;
  const coords = input.split(",");
  if (coords.length !== 2) return null;
  if (Number.isNaN(+coords[0]) || Number.isNaN(+coords[1])) return null;
  return { lat: parseFloat(coords[0]), lng: parseFloat(coords[1]) };
}

export function toCombined(state: LocationState): LocationDropdownItem[] {
  let coords: CoordsDropdownItem[] = [];
  if (state.coords) {
    const { lat, lng } = state.coords;
    const displayName = `(${lat}, ${lng})`;
    const coordinates: CoordsDropdownItem = {
      id: displayName,
      displayName,
      type: "coords",
      coords: state.coords
    };
    coords = [coordinates];
  }
  return [...coords, ...state.places, ...state.devices];
}

export function toSections(state: LocationState): Section[] {
  const sections: Section[] = [];

  if (state.coords) {
    const { lat, lng } = state.coords;
    const displayName = `(${lat}, ${lng})`;
    const coords: CoordsDropdownItem = {
      id: displayName,
      displayName,
      type: "coords",
      coords: state.coords
    };
    sections.push({
      section: "coordinates",
      data: [coords]
    });
  }

  if (state.places.length) {
    sections.push({
      section: "places",
      data: state.places
    });
  }

  if (state.devices.length) {
    sections.push({
      section: "devices",
      data: state.devices
    });
  }

  return sections;
}

export type LocationAction =
  | {
      type: "setDevices";
      devices: DeviceDropdownItem[];
    }
  | {
      type: "setPlaces";
      places: PlaceDropdownItem[];
    }
  | {
      type: "inputValueChanged";
      value: string;
    };

export const locationInitialState: LocationState = {
  coords: null,
  devices: [],
  places: []
};

export function locationReducer(state: LocationState, action: LocationAction): LocationState {
  switch (action.type) {
    case "inputValueChanged":
      return {
        ...state,
        coords: tryParseCoords(action.value)
      };
    case "setDevices":
      return {
        ...state,
        devices: action.devices
      };
    case "setPlaces":
      return {
        ...state,
        places: action.places
      };
  }

  return state;
}
