import { Navigate } from "react-router-dom";

import { useMobileNetworksDashboard } from "@src/Hooks/Permissions/mobileNetworksDashboard";
import { useServiceOrchestration } from "@src/Hooks/Permissions/serviceOrchestration";

import { useCloudManagerDashboard } from "./Hooks/Permissions/cloudManagerDashboard";
import { useCanAccessGlobalSettingsDashboard } from "./Hooks/Permissions/globalSettingsDashboard";
import { useInfrastructureDashboard } from "./Hooks/Permissions/infrastructureDashboard";
import { useLandingPage } from "./Hooks/Permissions/landingPage";
import { useSoftwarePublisher } from "./Hooks/Permissions/softwarePublisher";
import { useCanAccessTenantSettingsDashboard } from "./Hooks/Permissions/tenantSettingsDashboard";

export function DefaultPageRedirect() {
  const { allowed: allowServiceOrchestration } = useServiceOrchestration();
  const { allowed: allowMobileNetworks } = useMobileNetworksDashboard();
  const { allowed: allowCloudManager } = useCloudManagerDashboard();
  const { allowed: showLanding } = useLandingPage();
  const { allowed: allowSoftwarePublisher } = useSoftwarePublisher();
  const { allowed: allowInfra } = useInfrastructureDashboard();
  const { allowed: allowSettings } = useCanAccessTenantSettingsDashboard();
  const { allowed: allowGlobalSettings } = useCanAccessGlobalSettingsDashboard();

  if (
    allowServiceOrchestration == null ||
    allowMobileNetworks == null ||
    allowCloudManager == null ||
    allowInfra == null ||
    allowSoftwarePublisher == null ||
    allowSettings == null ||
    allowGlobalSettings == null ||
    showLanding == null
  ) {
    return null;
  }

  const landingPage = { url: "home", hasPermission: showLanding };
  const servicesDashboard = {
    url: "service-orchestration",
    hasPermission: allowServiceOrchestration
  };
  const softwarePublisherDashboard = {
    url: "software-publisher",
    hasPermission: allowSoftwarePublisher
  };
  const infraDashboard = { url: "infrastructure", hasPermission: allowInfra };
  const tenantSettingsDashboard = { url: "settings", hasPermission: allowSettings };
  const globalSettingsDashboard = { url: "global-settings", hasPermission: allowGlobalSettings };
  const mobileNetworksDashboard = { url: "mobile-networks", hasPermission: allowMobileNetworks };
  const cloudManagerDashboard = { url: "cloud-manager", hasPermission: allowCloudManager };

  const dashboards = [
    landingPage,
    servicesDashboard,
    infraDashboard,
    tenantSettingsDashboard,
    globalSettingsDashboard,
    mobileNetworksDashboard,
    cloudManagerDashboard,
    softwarePublisherDashboard
  ];

  const redirectPath = dashboards.find(d => d.hasPermission)?.url || "no-dashboard";

  return <Navigate to={redirectPath} />;
}
