import { useCallback } from "react";
import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";

import { ChartInformation } from "@src/Components/ChartSelector/ChartInformation";
import { SidePanel } from "@src/Components/SidePanel";
import { BlockChart } from "@src/Generated/graphql";

import { useFetchTemplateInformation } from "../fetchTemplateInformation";

interface useTemplateInformationProps {
  addVersionTemplate: (chart: BlockChart) => void;
}

export function TemplateInformation() {
  const { name } = useParams();
  const [searchParams] = useSearchParams();
  const version = searchParams.get("v");
  const navigate = useNavigate();

  const { addVersionTemplate } = useOutletContext<useTemplateInformationProps>();
  const templateInfo = useFetchTemplateInformation(name ?? null, version ?? null);

  const close = useCallback(() => {
    navigate("..");
  }, [navigate]);

  return (
    <SidePanel show={true} close={close}>
      <ChartInformation
        addVersionChart={addVersionTemplate}
        addButtonText="Add selected version to Editor"
        {...templateInfo}
      />
    </SidePanel>
  );
}
