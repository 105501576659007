import { ChartMetaInput, PublishBlockChartInput } from "@src/Generated/graphql";

export interface BlockPublisherForm {
  templateName: string;
  displayName: string;
  selectedVersion: string;
  values: string;
  descriptor: ChartMetaInput;
  overrides: string;
}

export function serialiseBlock(form: BlockPublisherForm): PublishBlockChartInput {
  return {
    templateName: form.templateName,
    templateVersion: form.selectedVersion,
    chartMeta: form.descriptor,
    valuesYaml: form.values,
    overridesYaml: form.overrides
  };
}
