import { FormikProps } from "formik";
import { Dispatch, SetStateAction, useEffect } from "react";
import { NavigateFunction, Outlet, useNavigate } from "react-router-dom";

import { LocalStorageAction } from "@src/Reducers/localStorageReducer";

import { PublishState } from "./publishBlock";
import { BlockPublisherForm } from "./serialise";

interface Context extends FormikProps<BlockPublisherForm> {
  publishState: PublishState;
  setPublishState: Dispatch<SetStateAction<PublishState>>;
  navigate: NavigateFunction;
}

interface BlockPublisherFormProps extends FormikProps<BlockPublisherForm> {
  publishState: PublishState;
  setPublishState: Dispatch<SetStateAction<PublishState>>;
  dispatch: Dispatch<LocalStorageAction>;
}

export function BlockPublisherForm({
  publishState,
  setPublishState,
  dispatch,
  ...formikProps
}: BlockPublisherFormProps) {
  const navigate = useNavigate();

  useEffect(() => {
    if (formikProps.values) {
      dispatch({
        type: "change",
        value: JSON.stringify(formikProps.values)
      });
    }
  }, [formikProps.values, dispatch]);

  const context: Context = {
    publishState,
    setPublishState,
    navigate,
    ...formikProps
  };

  return <Outlet context={context} />;
}
