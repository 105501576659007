import { HTMLAttributes, ReactNode } from "react";
import styled, { IStyledComponent } from "styled-components";

import { Clickable } from "../Buttons/Clickable";
import { InputTagMarker } from "../Tags";

export const TagsContainer = styled.div`
  grid-column: 2;
  min-height: calc(1.6em + 18px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px 10px;
  margin-bottom: 4px;
`;

const FixedTag = styled(InputTagMarker)`
  color: gray;
`;
interface SelectedInputTagsProps<T> {
  tags: T[];
  fixedTags?: T[];
  remove: (index: number) => void;
  getTagKey: (tag: T) => string;
  renderTagContent: (tag: T) => ReactNode;
  StyledTag?: IStyledComponent<"web", HTMLAttributes<HTMLSpanElement>>;
}

export function SelectedInputTags<T>({
  tags,
  fixedTags = [],
  remove,
  getTagKey,
  renderTagContent,
  StyledTag = InputTagMarker
}: SelectedInputTagsProps<T>) {
  return (
    <TagsContainer>
      {fixedTags.map(t => (
        <FixedTag key={getTagKey(t)}> {renderTagContent(t)}</FixedTag>
      ))}
      {tags.map((t, i) => (
        <StyledTag key={getTagKey(t)}>
          {renderTagContent(t)}
          <Clickable type="button" onClick={() => remove(i)}>
            ✕
          </Clickable>
        </StyledTag>
      ))}
    </TagsContainer>
  );
}
