import { css, styled } from "styled-components";

export const List = css`
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

export const FirstLine = styled.div<{ $isGrid: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  ${({ $isGrid }) => !$isGrid && List}
`;

export const Image = styled.img`
  display: block;
  max-height: 30px;
  object-fit: contain;
  object-position: left top;
`;

export const ChartName = styled.h3`
  margin-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 230px;
`;

export const ChartProvider = styled.p`
  color: rgba(0, 0, 0, 0.7);
  margin: 0;
`;

export const NoProvider = styled.span`
  font-style: italic;
  font-weight: 600;
`;

export const ChartDescription = styled.p`
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  height: 2rem;
  line-height: 1rem;
  &:after {
    right: 0px;
    width: 70%;
    bottom: 0px;
    height: 1.2rem;
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, 0)
      linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255) 90%) repeat scroll 0% 0%;
  }
`;

export const AddButton = styled.button.attrs({ type: "button" })`
  width: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
  background: white;
  border: 1px solid ${({ theme }) => theme.border};
  text-transform: capitalize;
  cursor: pointer;
  transition: background 200ms;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background: #ccc;
  }
`;

export const AddMoreButton = styled(AddButton)`
  background: ${({ theme }) => theme.success};
  border: 1px solid ${({ theme }) => theme.success};
  transition: color 200ms;

  &:hover {
    color: white;
    background: ${({ theme }) => theme.success};
  }
`;

export const SelectedTag = styled.span`
  background: ${({ theme }) => theme.success};
  border: 1px solid ${({ theme }) => theme.success};
  color: white;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
`;

export const Instances = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-left: auto;
`;
