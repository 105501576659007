import { MouseEvent, useCallback, useRef } from "react";
import styled, { css } from "styled-components";

import { FilterItem, Title } from "@src/Components/ChartSelector/ChartSelectorList";
import { Skeleton } from "@src/Components/Skeleton";
import { useToggle } from "@src/Hooks/toggle";

import { ThinTextButton } from "../Buttons/Text";
import { FilterEntity } from "./filterEntity";

const ListWrapper = styled.div`
  border: 1px solid #f0f0f0;
  padding: 4px 16px;
`;

const ListTitle = styled(Title)`
  width: 100%;
  margin: 0;
  font-size: 13px;
  padding: 5px 0;
  margin: 5px 0;
  border-bottom: 1px solid #f0f0f0;
`;

const SkeletonList = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 40px;
  gap: 5px;
`;

interface FiltersListProps<T extends string, V> {
  title: string;
  fieldFilter: T;
  availableItems: V[];
  otherItems: V[];
  filters: V[];
  selectFilter: (filter: FilterEntity<T, V>) => void;
  removeFilter: (filter: FilterEntity<T, V>) => void;
  loading: boolean;
  getItemKey: (item: V) => string;
  renderItem: (item: V) => string;
  checkSelected: (filters: V[], item: V) => boolean;
}

export function FiltersList<T extends string, V>({
  title,
  loading,
  availableItems,
  otherItems,
  ...filterListProps
}: FiltersListProps<T, V>) {
  return (
    <ListWrapper>
      <ListTitle>{title}</ListTitle>
      {loading ? (
        <SkeletonList>
          {[...Array(10).keys()].map(i => (
            <Skeleton key={i} height={18} style={{ zIndex: 0 }} />
          ))}
        </SkeletonList>
      ) : (
        <>
          <List entity={title} items={availableItems} {...filterListProps} />

          {otherItems.length > 0 && (
            <List isOthers entity={title} items={otherItems} {...filterListProps} />
          )}
        </>
      )}
    </ListWrapper>
  );
}

const SubTitle = styled.b`
  font-size: 13px;
  margin: 4px 0;
`;

const collapsedCss = css`
  max-height: 250px;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    height: 3rem;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(139, 167, 32, 0) 0%, rgba(255, 255, 255, 1) 100%);
    pointer-events: none;
  }
`;

const ListSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const ListSection = styled.div<{ $showAll: boolean }>`
  position: relative;
  ${({ $showAll }) => !$showAll && collapsedCss};
`;

const ToggleShowButton = styled(ThinTextButton)`
  align-self: flex-end;
  font-size: 13px;
`;

interface ListProps<T extends string, V> {
  entity: string;
  fieldFilter: T;
  items: V[];
  filters: V[];
  isOthers?: boolean;
  selectFilter: (filter: FilterEntity<T, V>) => void;
  removeFilter: (filter: FilterEntity<T, V>) => void;
  getItemKey: (item: V) => string;
  renderItem: (item: V) => string;
  checkSelected: (filters: V[], item: V) => boolean;
}

function List<T extends string, V>({
  isOthers = false,
  entity,
  fieldFilter,
  items,
  filters,
  selectFilter,
  removeFilter,
  getItemKey,
  renderItem,
  checkSelected
}: ListProps<T, V>) {
  const renderItems: V[] = items.sort((a, b) =>
    filters.includes(a) ? (filters.includes(b) ? 0 : -1) : 1
  );

  const initialShowAll = useRef(items.length <= 7 && !isOthers);

  const { state: showAllState, toggle } = useToggle(initialShowAll.current);

  const showAll = showAllState || items.length <= 7;

  const onClickSelect = useCallback(
    (item: V) => {
      selectFilter({ type: fieldFilter, value: item });
    },
    [fieldFilter, selectFilter]
  );

  const onRemove = useCallback(
    (e: MouseEvent, item: V) => {
      e.stopPropagation();
      removeFilter({ type: fieldFilter, value: item });
    },
    [fieldFilter, removeFilter]
  );

  return (
    <ListSectionWrapper>
      <ListSection $showAll={showAll}>
        {isOthers && (
          <div>
            <SubTitle>Others</SubTitle>
          </div>
        )}
        {renderItems.map(item => (
          <FilterItem
            onClick={() => onClickSelect(item)}
            key={getItemKey(item)}
            $selected={checkSelected(filters, item)}
          >
            {renderItem(item)}
            <span onClick={e => onRemove(e, item)} />
          </FilterItem>
        ))}
        {renderItems.length === 0 && (
          <FilterItem $selected={false}>No {entity} available</FilterItem>
        )}
      </ListSection>
      {items.length > 7 && (
        <ToggleShowButton onClick={toggle}>Show {showAll ? "less" : "more"}</ToggleShowButton>
      )}
    </ListSectionWrapper>
  );
}
