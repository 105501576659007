import { Formik } from "formik";
import { Dispatch } from "react";
import * as Yup from "yup";

import { LocalStorageAction } from "@src/Reducers/localStorageReducer";

import { BlockPublisherForm } from "./BlockPublisherForm";
import { usePublishBlock } from "./publishBlock";
import { BlockPublisherForm as BlockPublisherFormType } from "./serialise";

interface BlockPublisherProps {
  formValues: BlockPublisherFormType;
  dispatch: Dispatch<LocalStorageAction>;
}

const versionRegex =
  /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;

const BlockPublishSchema = Yup.object().shape({
  templateName: Yup.string().required("template name cannot be empty"),
  selectedVersion: Yup.string().required("selected version is needed"),
  values: Yup.string().required("template values cannot be empty"),
  overrides: Yup.string(),
  descriptor: Yup.object()
    .required("block descriptor cannot be empty")
    .shape({
      name: Yup.string().required("name cannot be empty"),
      version: Yup.string()
        .matches(
          versionRegex,
          "version must follow the semantic versioning conventions (i.e: 1.0.0)"
        )
        .required("version cannot be empty"),
      displayName: Yup.string().required("display name cannot be empty"),
      vendor: Yup.string().required("vendor cannot be empty"),
      description: Yup.string().required("description cannot be empty"),
      categories: Yup.array()
        .required("categories cannot be empty")
        .min(1, "block descriptor should have at least 1 category")
    })
});

export function BlockPublisher({ formValues, dispatch }: BlockPublisherProps) {
  const { publish, publishState, setPublishState } = usePublishBlock();

  return (
    formValues && (
      <Formik<BlockPublisherFormType>
        initialValues={formValues}
        onSubmit={publish}
        validationSchema={BlockPublishSchema}
        validateOnMount={true}
      >
        {formikProps => (
          <BlockPublisherForm
            publishState={publishState}
            setPublishState={setPublishState}
            dispatch={dispatch}
            {...formikProps}
          />
        )}
      </Formik>
    )
  );
}
