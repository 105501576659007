import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { cloudManager, mobileNetworksDashboard } from "@src/Hooks/Dashboard/dashboards";
import { useCloudManagerDashboard } from "@src/Hooks/Permissions/cloudManagerDashboard";
import { useMobileNetworksDashboard } from "@src/Hooks/Permissions/mobileNetworksDashboard";
import { useCanReadSim } from "@src/Hooks/Permissions/sim";

interface LandingPermissionsProps {
  networkId: string | null;
  coreId: string | null;
}

export function useLandingPermissions({
  networkId,
  coreId
}: LandingPermissionsProps): LandingPermissionsResult {
  const orgCtx = useOrgCtx() ?? null;

  const { allowed: canReadSIM } = useCanReadSim(coreId);
  const { allowed: allowedMobileNetworks } = useMobileNetworksDashboard();
  const { allowed: allowedCloudManager } = useCloudManagerDashboard();

  return {
    appManager: {
      allowed: allowedCloudManager,
      url: appManagerUrl(orgCtx)
    },
    infrastructureManager: {
      allowed: allowedCloudManager,
      url: infraManagerUrl(orgCtx)
    },
    mpnManager: {
      allowed: allowedMobileNetworks,
      url: mpnManagerUrl(orgCtx, networkId)
    },
    simCardsTable: {
      allowed: canReadSIM && allowedMobileNetworks,
      url: simCardsTableUrl(orgCtx, networkId, coreId)
    }
  };
}

export function appManagerUrl(org: string | null) {
  return cloudManager.route({ org, subRoute: "deployed-apps" });
}
export function infraManagerUrl(org: string | null) {
  return cloudManager.route({ org, subRoute: "cloud-sites/inventory" });
}
export function mpnManagerUrl(org: string | null, network: string | null) {
  return mobileNetworksDashboard.route({ org, network, subRoute: "operate/overview" });
}
export function simCardsTableUrl(org: string | null, network: string | null, core: string | null) {
  return mobileNetworksDashboard.route({ org, network, subRoute: `sim-cards/${core}/manage` });
}

export interface LandingPermission {
  allowed: boolean | null;
  url: string;
}

interface LandingPermissionsResult {
  appManager: LandingPermission;
  infrastructureManager: LandingPermission;
  mpnManager: LandingPermission;
  simCardsTable: LandingPermission;
}
