import { ReactNode } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";

import { ButtonGroupModal } from "../Buttons/ButtonGroup";
import { DangerButton } from "../Buttons/Danger";
import { GhostButton } from "../Buttons/Ghost";

const modalStyles: {
  content?: React.CSSProperties;
  overlay?: React.CSSProperties;
} = {
  content: {
    width: "50vw",
    inset: "20px 0 auto 0",
    margin: "0 auto",
    padding: "30px",
    font: "16px 'rawline',sans-serif"
  }
};

const ButtonWrapper = styled(ButtonGroupModal)`
  padding-top: 20px;
`;

interface PopupModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  confirmText?: string;
  cancelText?: string;
  children: ReactNode;
}

export function PopupModal({
  isOpen,
  onCancel,
  onConfirm,
  confirmText = "Overwrite",
  cancelText = "Preserve",
  children
}: PopupModalProps) {
  return (
    <ReactModal ariaHideApp={false} isOpen={isOpen} style={modalStyles}>
      {children}
      <ButtonWrapper>
        <GhostButton onClick={onCancel}>{cancelText}</GhostButton>
        <DangerButton onClick={onConfirm}>{confirmText}</DangerButton>
      </ButtonWrapper>
    </ReactModal>
  );
}
