import { FormikProps } from "formik";
import { ChangeEvent, useCallback, useRef } from "react";
import toast from "react-hot-toast";

import { ApolloQueryResult } from "@apollo/client";
import Remove from "@img/remove.svg";
import Reload from "@img/reset.svg";
import { ChartListItemSkeleton } from "@src/Components/ChartSelector/ChartListItemSkeleton";
import {
  ChartListWrapper,
  FirstLineButtonGroup,
  FlexRow,
  Grid,
  Item,
  ItemLine,
  Provider,
  RefreshClickable,
  RemoveClickable,
  ScrollDiv,
  Select,
  StyledH3
} from "@src/Components/ChartSelector/ChartListStyles";
import { ChartName, Image, NoProvider } from "@src/Components/ChartSelector/ChartStyles";
import { EditorStatus } from "@src/Components/EmbeddedEditor/editorStatus";
import { PublisherEditorAction } from "@src/Components/EmbeddedEditor/Publisher/publisherReducerTypes";
import { ConfirmActionModal } from "@src/Components/Modal/ConfirmActionModal";
import { PopupModal } from "@src/Components/Modal/PopupConfirmModal";
import { ChartMetaInput, FetchTemplateChartQuery, TemplateChart } from "@src/Generated/graphql";
import { useToggle } from "@src/Hooks/toggle";

import { PublisherEditorState } from "../editorPublisherReducer";
import { BlockPublisherForm } from "../serialise";

interface SelectedTemplateBlockProps extends FormikProps<BlockPublisherForm> {
  block: BlockPublisherForm;
  removeTemplate: () => void;
  editorState: PublisherEditorState;
  editorDispatch: React.Dispatch<PublisherEditorAction>;
  refetchTemplate: (variables?: {
    name: string;
    version?: string;
  }) => Promise<ApolloQueryResult<FetchTemplateChartQuery>>;
  templateChart: Omit<TemplateChart, "name" | "version"> | null;
  loading: boolean;
}

export function SelectedTemplateBlock({
  block,
  removeTemplate,
  editorState,
  editorDispatch,
  refetchTemplate,
  templateChart,
  loading,
  ...formikProps
}: SelectedTemplateBlockProps) {
  const { displayName, selectedVersion, templateName: name } = block;
  const { setFieldValue, setFormikState } = formikProps;

  const { state: isOpenRemove, on: openRemove, off: hideRemove } = useToggle();
  const removeItem = useCallback(() => {
    removeTemplate();
    hideRemove();
  }, [removeTemplate, hideRemove]);

  const selectedVersionRef = useRef(selectedVersion);

  const { state: isModalOpen, on: open, off: close } = useToggle();

  const fetchAndSetInfo = useCallback(() => {
    return refetchTemplate({ name, version: selectedVersionRef.current }).then(({ data }) => {
      if (data?.templateChart) {
        editorDispatch({
          type: "changeVersion",
          payload: {
            values: data.templateChart.valuesYaml ?? "",
            overrides: data.templateChart.overridesYaml ?? ""
          }
        });
        const newDescriptor: ChartMetaInput = {
          name: "",
          version: "",
          displayName: data.templateChart.displayName ?? "",
          vendor: data.templateChart.vendor ?? "",
          description: data.templateChart.description ?? "",
          iconUrl: data.templateChart.logoUrl ?? "",
          categories: data.templateChart.categories ?? []
        };
        setFormikState(prev => ({
          ...prev,
          values: {
            ...prev.values,
            selectedVersion: selectedVersionRef.current,
            descriptor: newDescriptor,
            values: data.templateChart?.valuesYaml ?? "",
            overrides: data.templateChart?.overridesYaml ?? ""
          }
        }));
      } else {
        toast.error("failed to fetch defaults for selected version");
      }
    });
  }, [editorDispatch, refetchTemplate, name, setFormikState]);

  const onChangeVersion = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const version = e.target.value;
      if (selectedVersionRef.current === version) return;
      selectedVersionRef.current = version;

      if (
        editorState.overrides.status !== EditorStatus.Default ||
        editorState.values.status !== EditorStatus.Default
      ) {
        open();
        return;
      } else {
        fetchAndSetInfo();
      }
    },
    [editorState.overrides.status, editorState.values.status, fetchAndSetInfo, open]
  );

  const replaceWithDefaults = useCallback(() => {
    fetchAndSetInfo().then(() => {
      close();
    });
  }, [close, fetchAndSetInfo]);

  const setVersionNoOverwrites = useCallback(() => {
    setFieldValue("selectedVersion", selectedVersionRef.current);
    close();
  }, [close, setFieldValue]);

  const refreshTemplate = useCallback(() => refetchTemplate(), [refetchTemplate]);

  const hideBlock = !templateChart || name.length === 0;

  return (
    <ChartListWrapper>
      <FlexRow>
        <StyledH3>Selected template</StyledH3>
      </FlexRow>
      <ScrollDiv>
        {!loading ? (
          <>
            {!hideBlock && (
              <Item $selected={true} title={`${displayName} block`}>
                <ItemLine>
                  <Image
                    src={templateChart?.logoUrl ?? undefined}
                    alt={`${templateChart?.vendor || templateChart?.displayName} logo`}
                  />
                  <FirstLineButtonGroup>
                    <RefreshClickable onClick={refreshTemplate} title="refresh template values">
                      <Reload width="14px" height="14px" />
                    </RefreshClickable>
                    <RemoveClickable onClick={openRemove} title="remove template">
                      <Remove width="18px" height="18px" />
                    </RemoveClickable>
                  </FirstLineButtonGroup>
                </ItemLine>
                <Grid>
                  <div>
                    <ChartName>{displayName}</ChartName>
                    <Provider>
                      by {templateChart?.vendor || <NoProvider>no vendor</NoProvider>}
                    </Provider>
                  </div>
                  <div>
                    <ChartName>version</ChartName>
                    <Select onChange={onChangeVersion} value={selectedVersion}>
                      {(templateChart?.availableVersions || []).map(v => (
                        <option key={v} value={v}>
                          {v}
                        </option>
                      ))}
                    </Select>
                  </div>
                </Grid>
              </Item>
            )}
          </>
        ) : (
          <ChartListItemSkeleton />
        )}
        <PopupModal
          isOpen={isModalOpen}
          onConfirm={replaceWithDefaults}
          onCancel={setVersionNoOverwrites}
        >
          <p>
            <strong>
              Do you want to overwrite current values in the editor with the defaults for the
              selected version?
            </strong>
          </p>
        </PopupModal>
        <ConfirmActionModal
          hideModal={hideRemove}
          modalShown={isOpenRemove}
          confirmAction={removeItem}
          title={`Remove ${block.displayName}`}
        >
          <p>The current configuration will be lost.</p>
        </ConfirmActionModal>
      </ScrollDiv>
    </ChartListWrapper>
  );
}
