import WebMercatorViewport from "@math.gl/web-mercator";
import { WebMercatorViewportProps } from "@math.gl/web-mercator/dist/web-mercator-viewport";

export interface MapState {
  width: number;
  height: number;
  longitude: number | null;
  latitude: number | null;
  zoom: number | null;
  mapStyle: string;
  shouldFly: {
    longitude: number;
    latitude: number;
    zoom: number;
  } | null;
  resetPosition: {
    longitude: number;
    latitude: number;
    zoom: number;
  } | null;
  interactiveLayerIds: string[] | null;
}

interface LatLng {
  lat: number;
  lng: number;
}

const isProd = process.env.NODE_ENV === "production";

export const MapStyle = {
  light: "mapbox://styles/dcarrera/ckljjxhe30ivt17o8no0lv1yg" + (isProd ? "" : "/draft"),
  dark: "mapbox://styles/dcarrera/ckljmacqg0l6p17msgzhl5kgp" + (isProd ? "" : "/draft"),
  satellite: "mapbox://styles/dcarrera/ckljmdhrg0i3517pujzji4bui" + (isProd ? "" : "/draft")
};

export const initialMapState: MapState = {
  width: 0,
  height: 0,
  longitude: null,
  latitude: null,
  zoom: null,
  mapStyle: MapStyle.light,
  shouldFly: null,
  resetPosition: null,
  interactiveLayerIds: null
};

export function checkMapReady(mapState: MapState) {
  const { longitude, latitude, zoom } = mapState;
  return longitude !== null && latitude !== null && zoom !== null;
}

type MapInit = {
  type: "init";
  center: LatLng;
  zoom: number;
};

type MapSetDimensions = {
  type: "setDimensions";
  width: number;
  height: number;
};

// Update viewport with no transition
type MapSetViewport = {
  type: "setViewport";
  center: LatLng;
  zoom: number;
};

type MapSetCenter = {
  type: "setCenter";
  center: LatLng;
};

type MapSetCenterZoom = {
  type: "setCenterZoom";
  center: LatLng;
  zoom: number;
};

type MapSetDeviceBounds = {
  type: "setDeviceBounds";
  bounds: [LatLng, LatLng];
};

type MapFlyToBounds = {
  type: "flyToBounds";
  bounds: [LatLng, LatLng];
};

type MapSetStyle = {
  type: "setStyle";
  style: string;
};

type MapAddInteractiveLayer = {
  type: "addInteractiveLayer";
  id: string;
};

type MapRemoveInteractiveLayer = {
  type: "removeInteractiveLayer";
  id: string;
};

export type MapAction =
  | MapInit
  | MapSetDimensions
  | MapSetViewport
  | MapSetCenter
  | MapSetCenterZoom
  | MapSetDeviceBounds
  | MapFlyToBounds
  | MapSetStyle
  | MapAddInteractiveLayer
  | MapRemoveInteractiveLayer;

function fitBounds(state: MapState, action: MapSetDeviceBounds | MapFlyToBounds) {
  return new WebMercatorViewport(state as WebMercatorViewportProps).fitBounds(
    [
      [action.bounds[0].lng, action.bounds[0].lat],
      [action.bounds[1].lng, action.bounds[1].lat]
    ],
    {
      padding: 180,
      maxZoom: 16
    }
  );
}

export function mapReducer(state: MapState, action: MapAction): MapState {
  switch (action.type) {
    case "init":
      return {
        ...state,
        latitude: action.center.lat,
        longitude: action.center.lng,
        zoom: action.zoom
      };
    case "setDimensions":
      return {
        ...state,
        width: action.width,
        height: action.height
      };
    case "setViewport":
      return {
        ...state,
        latitude: action.center.lat,
        longitude: action.center.lng,
        zoom: action.zoom,
        shouldFly: null
      };
    case "setCenter":
      return {
        ...state,
        shouldFly: {
          latitude: action.center.lat,
          longitude: action.center.lng,
          zoom: Math.max(state.zoom || 0, 16)
        }
      };
    case "setCenterZoom":
      return {
        ...state,
        shouldFly: {
          latitude: action.center.lat,
          longitude: action.center.lng,
          zoom: action.zoom
        }
      };
    case "setDeviceBounds":
      const devicePosition = fitBounds(state, action);
      return {
        ...state,
        ...devicePosition,
        resetPosition: devicePosition
      };
    case "flyToBounds":
      return {
        ...state,
        shouldFly: fitBounds(state, action)
      };
    case "setStyle":
      return {
        ...state,
        mapStyle: action.style
      };
    case "addInteractiveLayer":
      return {
        ...state,
        interactiveLayerIds: Array.from(new Set(state.interactiveLayerIds).add(action.id))
      };
    case "removeInteractiveLayer":
      const ids = new Set(state.interactiveLayerIds);
      ids.delete(action.id);
      return {
        ...state,
        interactiveLayerIds: ids.size ? Array.from(ids) : null
      };
  }
}
