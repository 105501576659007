import { editor } from "monaco-editor";
import { useCallback, useEffect, useRef } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { useElementSize } from "usehooks-ts";

import { ApolloError } from "@apollo/client";
import RemoveIcon from "@img/remove.svg";
import Editor from "@monaco-editor/react";
import {
  Actions,
  AddChartButton,
  Categories,
  Category,
  ChartInfoText,
  ChartInfoWrapper,
  Content,
  FirstLine,
  Info,
  Label,
  Logo,
  Title,
  VersionSelect
} from "@src/Components/ChartSelector/ChartInfoStyles";
import { iconSize } from "@src/Components/iconSize";
import { NotFound, NotFoundMessage } from "@src/Components/NotFound";
import { BlockChart, TemplateChart } from "@src/Generated/graphql";

import { PrimaryTextButton } from "../Buttons/Text";
import { ChartInformationSkeleton } from "./ChartInfoSkeleton";
import { NoProvider } from "./ChartStyles";

const Remove = styled(RemoveIcon)`
  ${iconSize}
`;

const LabelRemoveButtonWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const RemoveBlockButton = styled(PrimaryTextButton)`
  display: inline-flex;
  align-items: center;
  padding: 10px;
`;

interface ChartInformationProps {
  addVersionChart: (chart: TemplateChart | BlockChart) => void;
  addButtonText: string;
  showDeleteModal?: () => void;
  loading: boolean;
  chart: TemplateChart | BlockChart | null;
  error?: ApolloError;
}

export function ChartInformation({
  addVersionChart,
  addButtonText,
  showDeleteModal,
  loading,
  chart,
  error
}: ChartInformationProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const version = searchParams.get("v");
  const changeVersion = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      navigate(`../${chart?.name}?v=${e.target.value}`);
    },
    [navigate, chart]
  );
  const [blockInfoRef, { width }] = useElementSize();

  const editorDefVal = useRef<editor.IStandaloneDiffEditor | editor.IStandaloneCodeEditor>();

  const renderNumbers = width > 800;

  const addChart = useCallback(() => {
    if (!chart) return;
    addVersionChart(chart);
  }, [chart, addVersionChart]);

  useEffect(() => {
    editorDefVal.current?.layout();
  }, [width]);

  if (loading) return <ChartInformationSkeleton />;

  if (error) {
    return (
      <NotFound entity="Chart">
        <NotFoundMessage>
          <NavLink to={".."}>Close</NavLink> the panel and select another Chart
        </NotFoundMessage>
      </NotFound>
    );
  }

  if (!chart) return null;

  const {
    displayName,
    description,
    logoUrl,
    vendor,
    availableVersions,
    categories,
    overridesYaml
  } = chart;

  return (
    <Info>
      <FirstLine>
        <Logo src={logoUrl ?? undefined} alt={`${vendor || displayName} logo`} />
        <div>
          <Title>{displayName}</Title>
          <ChartInfoText>by {vendor || <NoProvider>no vendor</NoProvider>}</ChartInfoText>
          <Actions>
            <Label>Selected version:</Label>
            <VersionSelect onChange={changeVersion} value={version ?? undefined}>
              {(availableVersions || []).map(v => (
                <option key={v} value={v}>
                  {v}
                </option>
              ))}
            </VersionSelect>
            <AddChartButton onClick={addChart} disabled={!chart}>
              {addButtonText}
            </AddChartButton>
          </Actions>
        </div>
      </FirstLine>
      <Content>
        <Categories>
          <Label>Categories</Label>
          {(categories || []).map(c => (
            <Category key={c}>{c}</Category>
          ))}
        </Categories>
        <ChartInfoWrapper ref={blockInfoRef}>
          <LabelRemoveButtonWrapper>
            <Label>Description</Label>
            {showDeleteModal && (
              <RemoveBlockButton
                title="remove the selected block version"
                onClick={showDeleteModal}
              >
                remove version <Remove />
              </RemoveBlockButton>
            )}
          </LabelRemoveButtonWrapper>
          <ChartInfoText>{description}</ChartInfoText>
          <Label>Version tag</Label>
          <ChartInfoText>{version}</ChartInfoText>
          <Label>Block default values</Label>
          <Editor
            language="yaml"
            height="500px"
            theme="vs-dark"
            value={overridesYaml || ""}
            onMount={e => (editorDefVal.current = e)}
            options={{
              readOnly: true,
              lineNumbers: renderNumbers ? "on" : "off",
              minimap: {
                enabled: false
              }
            }}
          />
        </ChartInfoWrapper>
      </Content>
    </Info>
  );
}
