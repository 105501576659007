import { Navigate, useLocation } from "react-router-dom";

import { useOrgCtx } from "@src/Hooks/Context/orgCtx";

import { serviceOrchestrationRedirect } from "./serviceOrchestrationRedirect";

// Redirect from old /services routes into their respective service orchestration dashboard routes
export function ServiceOrchestrationRedirect() {
  const { pathname } = useLocation();
  const org = useOrgCtx() as string;

  if (!org) return <></>;
  const dest = serviceOrchestrationRedirect(pathname, org);
  return <Navigate to={dest} replace />;
}
