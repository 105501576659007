export function routesRedirect(path: string, initialRoute: string) {
  const matchPath = /^\/app\/([^\/]+\/){2}(?<path>.*)/;
  const match = path.match(matchPath);
  if (match && match?.groups?.path) {
    const path = match.groups.path;
    return `${initialRoute}/${path}`;
  } else {
    return initialRoute;
  }
}
