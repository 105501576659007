import { useQuery } from "@apollo/client";
import { TemplatesQuery, TemplatesQueryVariables } from "@src/Generated/graphql";
import { useLocalStorageKey } from "@src/Hooks/localStorageKey";
import { TemplatesStateKey } from "@src/SharedViews/ServiceDesigner/Marketplace/marketplaceReducer";
import { notEmpty } from "@src/Utils/filterEmpty";

import Templates from "./Templates.graphql";

export function useTemplates() {
  const { loading, error, data, refetch } = useQuery<TemplatesQuery, TemplatesQueryVariables>(
    Templates,
    {
      variables: { category: null, vendors: [] },
      errorPolicy: "all",
      notifyOnNetworkStatusChange: true
    }
  );

  const charts = data?.templateCharts ?? [];

  const repoUrl = data?.settings?.template?.url ?? null;
  const localStorageKey = useLocalStorageKey(TemplatesStateKey);

  return {
    loading,
    error,
    refetch,
    charts: charts.filter(notEmpty),
    repoUrl,
    localStorageKey
  };
}
