import { useOrgCtx } from "@src/Hooks/Context/orgCtx";

import { ApolloProviderWithOrgCtx } from "./Clients/Apollo/ApolloProvider";
import { validateOrgCtx } from "./Redirect/redirectValidOrgCtx";
import { RedirectValidOrgCtx } from "./Redirect/RedirectValidOrgCtx";

export function OrgCtxValidation() {
  const orgCtx = useOrgCtx() as string;

  if (!validateOrgCtx(orgCtx)) return <RedirectValidOrgCtx />;

  return <ApolloProviderWithOrgCtx />;
}
