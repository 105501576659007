import { UseSelectStateChange } from "downshift";
import styled from "styled-components";

import { Dropdown } from "@src/Components/Dropdown";

import { allCoresOption } from "./allCoresOption";
import { NetworkElement } from "./Infrastructure/NetworkElement";

export const DropdownWrapper = styled.div`
  width: 100%;
`;

export function getCoreIdentifier(core: NetworkElement | null) {
  if (!core) return "";
  if (core.uid === allCoresOption.uid) return core.displayname;
  return `${core?.displayname} - ${core?.uid}`;
}

interface CoreDropdownProps {
  coresList: NetworkElement[];
  onSelectCore: (core: UseSelectStateChange<NetworkElement>) => void;
  allowEmptyStart?: boolean;
  initialCore?: NetworkElement | null;
}

export function CoresDropdown({
  coresList,
  initialCore,
  onSelectCore,
  allowEmptyStart = false
}: CoreDropdownProps) {
  const defaultCore = !allowEmptyStart ? initialCore || getFirstCore(coresList) : initialCore;
  if (!defaultCore && !allowEmptyStart) return null;
  return (
    <DropdownWrapper>
      <Dropdown
        items={coresList}
        getItemDisplayName={getCoreIdentifier}
        getItemKey={getCoreIdentifier}
        initialSelectedItem={defaultCore}
        placeholder="Select a Core"
        onSelectedItemChange={onSelectCore}
      />
    </DropdownWrapper>
  );
}

export function getFirstCore(cores: { uid: string }[]) {
  return cores.sort((a, b) => a.uid.localeCompare(b.uid)).at(0);
}
