import { AuditLogNodeFragment } from "@src/Generated/graphql";

interface Entity {
  __typename?: string;
}

function isBlockChart(entity: unknown): entity is { blockChartName: string } {
  return (entity as Entity)?.__typename === "BlockChart";
}

export function entityName(entity: AuditLogNodeFragment["entity"]): string | null {
  if (isBlockChart(entity)) return entity.blockChartName;
  return entity?.name ?? null;
}
