import styled from "styled-components";

import { FiltersLabel } from "../Filters/SearchFilters";
import { CheckboxSlider, CheckBoxWrapper } from "../Input/CheckboxSlider";

const LabelCheckboxWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

const SliderWrapper = styled(CheckBoxWrapper)`
  padding: 0;
`;

interface CheckboxSliderProps {
  checked: boolean;
  toggleCheckbox: () => void;
  label: string;
}

export function ChartFilterCheckbox({ checked, toggleCheckbox, label }: CheckboxSliderProps) {
  return (
    <LabelCheckboxWrapper>
      <FiltersLabel>{label}: </FiltersLabel>
      <SliderWrapper>
        <CheckboxSlider
          name="any or all"
          labelLeft="Any"
          labelRight="All"
          checked={checked}
          onChange={toggleCheckbox}
        />
      </SliderWrapper>
    </LabelCheckboxWrapper>
  );
}
