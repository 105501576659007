import { useLocation } from "react-router-dom";

import {
  cloudManager,
  DashboardObject,
  globalSettings,
  infrastructureDashboard,
  landingPage,
  mobileNetworksDashboard,
  noDashboard,
  serviceOrchestration,
  softwarePublisher,
  tenantSettings
} from "./dashboards";

export type Dashboard =
  | "service-orchestration"
  | "infrastructure"
  | "mobile-networks"
  | "cloud-manager"
  | "home"
  | "software-publisher"
  | "settings"
  | "global-settings"
  | "no-dashboard";

interface CurrentDashboardState {
  isLanding: boolean;
  isInfrastructure: boolean;
  isMobileNetworks: boolean;
  isCloudManager: boolean;
  isServiceOrchestration: boolean;
  isSoftwarePublisher: boolean;
  isTenantSettings: boolean;
  isGlobalSettings: boolean;
  dashboard: DashboardObject;
}

export function useCurrentDashboard(): CurrentDashboardState {
  const { pathname } = useLocation();
  const route = pathname.split("/")[3] as Dashboard;

  const dashboardObject = getDashboardObject(route, pathname);

  return {
    isLanding: dashboardObject?.name === "home",
    isServiceOrchestration: dashboardObject?.name === "service-orchestration",
    isInfrastructure: dashboardObject?.name === "infrastructure",
    isMobileNetworks: dashboardObject?.name === "mobile-networks",
    isCloudManager: dashboardObject?.name === "cloud-manager",
    isSoftwarePublisher: dashboardObject?.name === "software-publisher",
    isTenantSettings: dashboardObject?.name === "settings",
    isGlobalSettings: dashboardObject?.name === "global-settings",
    dashboard: dashboardObject
  };
}

function getDashboardObject(dashboard: Dashboard, pathname: string): DashboardObject {
  switch (dashboard) {
    case "home":
      return landingPage;
    case "service-orchestration":
      return serviceOrchestration;
    case "mobile-networks":
      return mobileNetworksDashboard;
    case "software-publisher":
      return softwarePublisher;
    case "cloud-manager":
      return cloudManager;
    case "infrastructure":
      return infrastructureDashboard;
    case "settings":
      return tenantSettings;
    case "global-settings":
      return globalSettings;
    default:
      return pathname.split("/").includes("global-settings") ? globalSettings : noDashboard;
  }
}
