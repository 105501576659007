import { Namespace } from "@src/Generated/permissions/namespace";
import { Permit } from "@src/Generated/permissions/permit";

import { usePermissionCheck } from "../permissionsCheck";

export function useCanReadAllOrgs() {
  return usePermissionCheck({
    objectType: Namespace.ObjectPolicy,
    objectId: ORG_OBJECT_POLICY_ID,
    relation: Permit.Read
  });
}

export function useCanCreateOrg() {
  return usePermissionCheck({
    objectType: Namespace.Env,
    objectId: NEARBY_ONE_ENV_ID,
    relation: Permit.CreateOrg
  });
}

export function useCanDeleteOrg(id: string | null) {
  return usePermissionCheck({
    objectType: Namespace.Org,
    objectId: id,
    relation: Permit.Delete
  });
}

export function useCanEditOrg(id: string | null) {
  return usePermissionCheck({
    objectType: Namespace.Org,
    objectId: id,
    relation: Permit.Write
  });
}
