import { NavLink } from "react-router-dom";

import { H2 } from "./Text";

export interface NoChartsProps {
  link: string;
  linkText: string;
  entity: string;
}

export function NoCharts({ link, entity, linkText }: NoChartsProps) {
  return (
    <>
      <H2>There are no {entity}s to configure</H2>
      <p>
        Try adding a {entity} from the <NavLink to={link}>{linkText}</NavLink> and configure it with
        your custom values or try it with the default values
      </p>
    </>
  );
}
