import MonitoringIcon from "@img/monitoring.svg";
import NetworkIcon from "@img/network.svg";
import ReportsIcon from "@img/reports.svg";
import TroubleshootIcon from "@img/screwdriver-wrench-solid.svg";
import SimCardIcon from "@img/sim-card.svg";
import ConfigureIcon from "@img/sliders-solid.svg";
import SettingsIcon from "@img/system.svg";
import { NavIcons } from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { mobileNetworksDashboard } from "@src/Hooks/Dashboard/dashboards";

import { useTranslation } from "react-i18next";
import { useMPNNavPerms } from "./mpnNavPermissions";
import { useSelectedNetwork } from "./networks";

export function MPNNavbar() {
  const { network } = useSelectedNetwork();
  const orgCtx = useOrgCtx();
  const { t } = useTranslation("nav");

  const rootPath = mobileNetworksDashboard.route({ org: orgCtx ?? null, network: network?.uid });

  const { queriesSettled, canViewConfigManagement, canViewCoreManager, canViewTroubleshooting } =
    useMPNNavPerms();

  if (!queriesSettled) return null;
  return (
    <NavIcons>
      <NavItem
        linkTo={rootPath + "/operate"}
        label={t("mpn.operate")}
        icon={MonitoringIcon}
        disabled={!network}
      />
      {canViewConfigManagement && (
        <NavItem
          linkTo={rootPath + "/configure"}
          label={t("mpn.configure")}
          icon={ConfigureIcon}
          disabled={!network}
        />
      )}
      <NavItem
        linkTo={rootPath + "/sim-cards"}
        label={t("mpn.sims")}
        icon={SimCardIcon}
        disabled={!network}
      />
      <NavItem
        linkTo={rootPath + "/infrastructure"}
        label={t("mpn.infra")}
        icon={NetworkIcon}
        disabled={!network}
      />

      {canViewTroubleshooting && (
        <NavItem
          linkTo={rootPath + "/troubleshoot"}
          label={t("mpn.troubleshoot")}
          icon={TroubleshootIcon}
          disabled={!network}
        />
      )}
      <NavItem
        linkTo={rootPath + "/reports"}
        label={t("mpn.reports")}
        icon={ReportsIcon}
        disabled={!network}
      />
      {canViewCoreManager && (
        <NavItem
          linkTo={rootPath + "/settings"}
          label={t("mpn.settings")}
          icon={SettingsIcon}
          disabled={!network}
        />
      )}
    </NavIcons>
  );
}
