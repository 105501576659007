import styled from "styled-components";

import { ThinTextButton } from "@src/Components/Buttons/Text";

import { ElementLabel } from "../Label";
import { FilterEntity } from "./filterEntity";

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: baseline;
  margin-top: 16px;
`;

export const FiltersLabel = styled(ElementLabel)`
  font-size: 12px;
`;

const FilterSectionWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  & b {
    font-size: 14px;
  }
`;

const FilterLabel = styled.label`
  text-transform: capitalize;
`;

const Filter = styled.div`
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px 10px;
  position: relative;
  display: flex;
  gap: 5px;

  & > span {
    display: block;
    position: relative;
    cursor: pointer;
    height: 12px;
    width: 12px;
    align-self: center;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      width: 100%;
      background-color: #ccc;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:hover {
      &::before,
      &::after {
        background-color: ${({ theme }) => theme.failure};
      }
    }
  }
`;

interface SearchFiltersProps<T extends string, V> {
  removeFilter: (f: FilterEntity<T, V>) => void;
  clearFilters: () => void;
  filters: FilterEntity<T, V>[];
  renderFilter: (f: V) => string;
  renderKey: (f: V, t: T) => string;
}

export function SearchFilters<T extends string, V>({
  clearFilters,
  removeFilter,
  filters,
  renderFilter,
  renderKey
}: SearchFiltersProps<T, V>) {
  if (filters.length === 0) return null;
  return (
    <Wrapper>
      <>
        <FiltersLabel>Applied Filters:</FiltersLabel>
        <FilterSectionWrapper>
          {filters.map(f => (
            <Filter key={`${renderKey(f.value, f.type)}`}>
              {f.value ? (
                <>
                  <FilterLabel>{f.type}:</FilterLabel> {`"${renderFilter(f.value)}"`}
                  <span onClick={() => removeFilter(f)} />
                </>
              ) : (
                <em>no filters</em>
              )}
            </Filter>
          ))}
          <ThinTextButton onClick={clearFilters}>Clear filters</ThinTextButton>
        </FilterSectionWrapper>
      </>
    </Wrapper>
  );
}
