import { FormEvent, MutableRefObject, useCallback, useState } from "react";

import { LoginFlow, UiNode, UiNodeInputAttributes, UpdateLoginFlowBody } from "@ory/client";
import { getNodeId } from "@src/Hooks/selfService";

import { Flow } from "./Flow";
import { FieldInput } from "./NodeFieldInput";
import { NodeInput } from "./NodeInput";

interface OIDCLoginFormProps {
  flow: LoginFlow;
  node: UiNode;
  onSubmit: (values: UpdateLoginFlowBody) => Promise<void>;
  isStillMounted?: MutableRefObject<boolean>;
}

export function OIDCLoginForm({ node, flow, isStillMounted, onSubmit }: OIDCLoginFormProps) {
  const nodeAttributes = node.attributes as UiNodeInputAttributes;

  const [state, setState] = useState<{
    values: object;
    isLoading: boolean;
  }>({
    isLoading: false,
    values: {
      [nodeAttributes.name]: nodeAttributes.value
    }
  });

  const handleSubmit = useCallback(
    (e: MouseEvent | FormEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (state.isLoading) return Promise.resolve();

      setState(prevState => ({
        ...prevState,
        isLoading: true
      }));

      return onSubmit(state.values as UpdateLoginFlowBody).finally(() => {
        // We wait for reconciliation and update the state after 50ms
        // Done submitting - update loading status
        isStillMounted?.current &&
          setState(prevState => ({
            ...prevState,
            isLoading: false
          }));
      });
    },
    [setState, state, onSubmit, isStillMounted]
  );

  const id = getNodeId(node);
  return (
    <>
      <Flow flow={flow} onSubmit={handleSubmit}>
        <NodeInput
          input={FieldInput}
          key={id}
          node={node}
          attributes={nodeAttributes}
          disabled={state.isLoading}
          placeholder={nodeAttributes.value}
          value={nodeAttributes.value}
        />
      </Flow>
    </>
  );
}
