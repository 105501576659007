import { Navigate, useLocation } from "react-router-dom";

import { useDefaultOrg } from "@src/Hooks/defaultOrg";

import { redirectValidOrgCtx } from "./redirectValidOrgCtx";

export function RedirectValidOrgCtx() {
  const { pathname } = useLocation();
  const { org } = useDefaultOrg();

  if (!org) return <></>;

  const dest = redirectValidOrgCtx(pathname, org);
  return <Navigate to={dest} replace />;
}
