import { mobileNetworksDashboard } from "@src/Hooks/Dashboard/dashboards";

export function mobileNetworksRedirect(path: string, org: string) {
  const oldRoute = `/app/${org}/mpn`;
  const rootRoute = mobileNetworksDashboard.route({ org });

  if (path.includes(oldRoute)) {
    return routesRedirect(path, rootRoute);
  }

  return rootRoute;
}

function routesRedirect(path: string, initialRoute: string) {
  const matchPath = /\/app\/([^\/]+\/){2}(?<network>[^\/]+)(?<path>.*)/;
  const match = path.match(matchPath);

  const network = match?.groups?.network;
  const rest = match?.groups?.path;

  if (rest || network) {
    return `${initialRoute}/${network || ""}${rest || ""}`;
  } else {
    return initialRoute;
  }
}
