import { InputHTMLAttributes } from "react";
import styled from "styled-components";

const RadioButton = styled.input.attrs({ type: "radio" })`
  display: none;

  & + span {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: -2px 6px 0;
    vertical-align: middle;
    -moz-border-radius: 50%;
    border-radius: 50%;

    background-color: white;
    border: 4px solid white;

    &::after {
      content: "";
      position: absolute;
      height: 18px;
      width: 18px;
      border: 1px solid #b7bbc0;
      -moz-border-radius: 50%;
      border-radius: 50%;
      top: -4px;
      left: -4px;
    }
  }

  &:checked + span {
    background-color: #4a90e2;
  }
`;

const Label = styled.label`
  cursor: pointer;
  color: #444444;
  display: inline-block;
  margin-bottom: 10px;
`;

interface RadioInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

export function RadioInput({ label, ...rest }: RadioInputProps) {
  return (
    <Label>
      <RadioButton type="radio" {...rest} />
      <span />
      {label}
    </Label>
  );
}
