import { Navigate, useLocation } from "react-router-dom";

import { useOrgCtx } from "@src/Hooks/Context/orgCtx";

import { softwarePublisherRedirect } from "./softwarePublisherRedirect";

// Redirect from old /services/publisher routes into their respective software publisher dashboard
export function SoftwarePublisherRedirect() {
  const { pathname } = useLocation();
  const org = useOrgCtx() as string;

  if (!org) return <></>;
  const dest = softwarePublisherRedirect(pathname, org);
  return <Navigate to={dest} replace />;
}
