import styled from "styled-components";

import {
  Actions,
  Categories,
  ChartInfoText,
  ChartInfoWrapper,
  Content,
  FirstLine,
  Info,
  Label,
  Title
} from "@src/Components/ChartSelector/ChartInfoStyles";
import { Skeleton } from "@src/Components/Skeleton";

const ButtonSkeletonWrapper = styled.div`
  margin-left: auto;
`;

const CategoriesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export function ChartInformationSkeleton() {
  return (
    <Info>
      <FirstLine>
        <Skeleton width={200} height={80} />
        <div>
          <Title>
            <Skeleton width="60%" />
          </Title>
          <ChartInfoText>
            <Skeleton width={80} />
          </ChartInfoText>
          <Actions>
            <Skeleton height={30} width={250} />
            <ButtonSkeletonWrapper>
              <Skeleton height={45} width={200} />
            </ButtonSkeletonWrapper>
          </Actions>
        </div>
      </FirstLine>
      <Content>
        <Categories>
          <Label>Categories</Label>
          <CategoriesList>
            {[...Array(3).keys()].map(i => (
              <Skeleton key={i} width={75} height={30} borderRadius="20px" />
            ))}
          </CategoriesList>
        </Categories>
        <ChartInfoWrapper>
          <Label>Description</Label>
          <ChartInfoText>
            <Skeleton count={4.6} />
          </ChartInfoText>
          <Label>Version tag</Label>
          <ChartInfoText>
            <Skeleton width={50} />
          </ChartInfoText>
          <Label>Block default values</Label>
          <Skeleton width={"100%"} height={"60%"} />
        </ChartInfoWrapper>
      </Content>
    </Info>
  );
}
