import { FilterEntity } from "@src/Components/Filters/filterEntity";
import { getLSItemWithMigration } from "@src/Hooks/localStorageKey";
import { SortEnum } from "@src/Utils/sortByDisplayName";

export const MarketplaceStateKey = "MarketplaceState";
export const TemplatesStateKey = "TemplatesState";

export enum viewEnum {
  grid = 1,
  list
}

export type MarketplaceFilterType = "category" | "vendor" | "search term";

export interface MarketplaceState {
  inputFilter: string;
  filters: FilterEntity<MarketplaceFilterType, string>[];
  includeAllTexts: boolean;
  includeAllCategories: boolean;
  view: viewEnum;
  sort: SortEnum;
  localStorageKey: string;
}

export const initialState: MarketplaceState = {
  inputFilter: "",
  filters: [],
  includeAllTexts: true,
  includeAllCategories: true,
  view: viewEnum.grid,
  sort: SortEnum.AZ,
  localStorageKey: ""
};

export type FilterEntitySettings = keyof Pick<
  MarketplaceState,
  "includeAllTexts" | "includeAllCategories"
>;

export type MarketplaceAction =
  | {
      type: "changeInputFilter" | "swapView" | "swapSort" | "updateLSKey";
      value: string;
    }
  | { type: "selectFilter" | "removeFilter"; filter: FilterEntity<MarketplaceFilterType, string> }
  | { type: "toggleSetting"; field: FilterEntitySettings }
  | { type: "cleanAllFilters" };

export function initReducer(localStorageKey: string) {
  const localStorageValue = getLSItemWithMigration(
    window.localStorage,
    localStorageKey,
    initialState
  );
  const savedMarketplaceState = JSON.parse(localStorageValue) as MarketplaceState;

  return savedMarketplaceState
    ? { ...savedMarketplaceState, localStorageKey }
    : { ...initialState, localStorageKey };
}

export function marketplaceReducer(
  state: MarketplaceState,
  action: MarketplaceAction
): MarketplaceState {
  let newState: MarketplaceState;
  switch (action.type) {
    case "selectFilter":
      newState =
        state.filters.some(f => f.type === action.filter.type && f.value === action.filter.value) ||
        !action.filter.value
          ? state
          : {
              ...state,
              filters: [...state.filters, action.filter],
              inputFilter: action.filter.type === "search term" ? "" : state.inputFilter
            };
      break;
    case "removeFilter":
      newState = {
        ...state,
        filters: state.filters.filter(
          f => !(f.type === action.filter.type && f.value === action.filter.value)
        )
      };
      break;
    case "toggleSetting":
      newState = {
        ...state,
        [action.field]: !state[action.field]
      };
      break;
    case "changeInputFilter":
      newState = {
        ...state,
        inputFilter: action.value
      };
      break;
    case "swapView":
      newState = { ...state, view: parseInt(action.value) };
      break;
    case "swapSort":
      newState = { ...state, sort: parseInt(action.value) };
      break;
    case "cleanAllFilters":
      newState = { ...state, filters: [] };
      break;
    case "updateLSKey":
      newState = initReducer(action.value);
      break;
    default:
      newState = state;
      break;
  }
  window.localStorage.setItem(
    newState.localStorageKey,
    JSON.stringify({ ...newState, inputFilter: "" })
  );
  return newState;
}
