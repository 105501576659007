export enum SortEnum {
  AZ = 1,
  ZA
}
interface Sortable {
  displayName?: string | null;
}

export const sortByDisplayName =
  (sort: SortEnum = SortEnum.AZ) =>
  (a: Sortable, b: Sortable) => {
    if (!a.displayName) return 1;
    if (!b.displayName) return -1;
    return a.displayName.localeCompare(b.displayName) * (sort === SortEnum.ZA ? -1 : 1);
  };
