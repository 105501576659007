import "react-calendar/dist/Calendar.css";
import "./calendar-styles.css";

import { useCallback, useRef, useState } from "react";
import Calendar from "react-calendar";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { useElementSize, useOnClickOutside } from "usehooks-ts";

import { useToggle } from "@src/Hooks/toggle";

import { useLogNavigation } from "../logNavigation";
import { calculateCalendarState, getUTCDate } from "./dateUtils";
import { QuickRangeList } from "./QuickRangeList";
import { RangeSelectors } from "./RangeSelectors";

const ModalWrapper = styled.div<{ $position: { height: number } }>`
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  position: absolute;
  width: 500px;
  height: 280px;
  background-color: white;
  box-shadow: rgb(0, 0, 0, 0.5) 2px 2px 16px;
  top: ${({ $position }) => `calc(${$position.height}px + 10px)`};
  left: 0;
  font-size: small;
`;

const CalendarContainer = styled.div<{ $position: number }>`
  position: absolute;
  right: ${({ $position }) => `calc(-${$position}px - 10px )`};
`;

export interface TimePickerModalBodyProps {
  show: boolean;
  closeModal: () => void;
  position: { height: number; width: number };
}

export function TimePickerModalBody({ show, closeModal, position }: TimePickerModalBodyProps) {
  const [calendarContainerRef, { width }] = useElementSize();
  const updateQueryParams = useLogNavigation();

  const [searchParams] = useSearchParams();

  const from = searchParams.get("from");
  const to = searchParams.get("to");

  const [calendarState, setCalendarState] = useState<{ range: [Date, Date]; touched: boolean }>({
    range: calculateCalendarState(from, to),
    touched: false
  });

  const { state: isOpen, on: openCalendar, off: closeCalendar } = useToggle();

  const handleCalendarChange = useCallback(
    (value: [Date, Date]) => {
      setCalendarState({ range: value, touched: true });
    },
    [setCalendarState]
  );

  const handleOutsideClick = useCallback(() => {
    if (!isOpen) closeModal();
  }, [closeModal, isOpen]);

  const applyCalendarRange = useCallback(
    (range: [Date, Date]) => {
      const [from, to] = range;
      closeModal();
      closeCalendar();

      const fromUTC = getUTCDate(from.getTime());
      const toUTC = getUTCDate(to.getTime());
      setCalendarState({ range: [from, to], touched: false });
      updateQueryParams({ from: fromUTC.getTime(), to: toUTC.getTime() }, true);
    },
    [closeCalendar, closeModal, updateQueryParams]
  );

  const applyQuickRange = useCallback(
    (range: [string, string], alias: string) => {
      const [from, to] = range;
      closeModal();
      closeCalendar();
      setCalendarState({ range: calculateCalendarState(from, to), touched: false });
      updateQueryParams({ from, to, alias });
    },
    [closeModal, closeCalendar, updateQueryParams]
  );

  const modalRef = useRef(null);
  useOnClickOutside(modalRef, handleOutsideClick, "mouseup");

  const calendarRef = useRef(null);
  useOnClickOutside(calendarRef, closeCalendar, "mouseup");

  if (!show) return null;

  return (
    <ModalWrapper ref={modalRef} $position={position}>
      <RangeSelectors
        openCalendar={openCalendar}
        calendarState={calendarState}
        applyRange={applyCalendarRange}
      />
      {isOpen && (
        <CalendarContainer ref={calendarContainerRef} $position={width}>
          <div ref={calendarRef}>
            <Calendar
              selectRange={true}
              next2Label={null}
              prev2Label={null}
              value={calendarState.range}
              onChange={handleCalendarChange}
              locale="en-US"
            />
          </div>
        </CalendarContainer>
      )}
      <QuickRangeList applyRange={applyQuickRange} />
    </ModalWrapper>
  );
}
