import { FormikErrors } from "formik";
import styled from "styled-components";

import { InfoIcon } from "@src/Components/InfoIcon";

import { BlockPublisherForm } from "../serialise";

interface TemplateMissingStepsProps {
  errors: FormikErrors<BlockPublisherForm>;
}

const I = styled(InfoIcon)`
  margin-left: -2px;
`;

export function PublishBlockMissingSteps({ errors }: TemplateMissingStepsProps) {
  const designerErrors = {
    values: errors.values ? true : false,
    descriptor: errors.descriptor ? true : false,
    overrides: errors.overrides ? true : false
  };
  const hasDesignerErrors = Object.values(designerErrors).some(field => field);

  return (
    hasDesignerErrors && (
      <div>
        <I>In order to deploy a service, the following steps must be completed</I>
        <ul>
          {designerErrors.values && <li>Block values contains invalid/missing fields</li>}
          {designerErrors.descriptor && <li>Block descriptor contains invalid/missing fields</li>}
          {designerErrors.overrides && <li>Block overrides contains invalid/missing fields</li>}
        </ul>
      </div>
    )
  );
}
