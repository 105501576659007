import { GlobalSettingsNavbar } from "./AdminDashboard/GlobalSettingsNavbar";
import { TenantSettingsNavbar } from "./AdminDashboard/TenantSettingsNavbar";
import { useCurrentDashboard } from "./Hooks/Dashboard/currentDashboard";
import { InfrastructureNavbar } from "./InfrastructureDashboard/InfrastructureNavbar";
import { MECNavbar } from "./MECDashboard/MECNavBar";
import { MPNNavbar } from "./MPNDashboard/MPNNavbar";
import { ServicesNavbar } from "./ServicesDashboard/ServicesNavbar";
import { SoftwarePublisherNavbar } from "./SoftwarePublisher/SoftwarePublisherNavbar";

export function Navbar() {
  const { dashboard } = useCurrentDashboard();

  switch (dashboard.name) {
    case "service-orchestration":
      return <ServicesNavbar />;
    case "software-publisher":
      return <SoftwarePublisherNavbar />;
    case "mobile-networks":
      return <MPNNavbar />;
    case "cloud-manager":
      return <MECNavbar />;
    case "infrastructure":
      return <InfrastructureNavbar />;
    case "settings":
      return <TenantSettingsNavbar />;
    case "global-settings":
      return <GlobalSettingsNavbar />;
    default:
      return <></>;
  }
}
