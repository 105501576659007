import styled, { css } from "styled-components";

import { ValueType } from "@src/Components/Forms/DynamicForms/initFormConfig";

import { ReadOnly } from "../Tags";

const ReviewLabel = styled.label`
  grid-column: 1;
  justify-self: end;
  margin-right: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #858585;
  text-transform: capitalize;
  white-space: nowrap;
`;

const Field = styled.p`
  display: inline-block;
  margin-top: 0;
  &:last-child {
    margin: 0;
  }
`;

const ModifiedField = styled.p`
  text-decoration: line-through;
  margin: 0;
`;

const TextField = styled.div`
  text-overflow: ellipsis;
  overflow-x: auto;
  padding-bottom: 5px;

  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.primary} ${({ theme }) => theme.backgroundLight};

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.backgroundLight};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.primary};
    border-radius: 20px;
  }
`;

interface ObjectFieldReviewProps<T> {
  label: keyof T;
  element: T;
  fieldDiff?: Partial<T> | null;
}

export function ObjectFieldReview<T>({ label, element, fieldDiff }: ObjectFieldReviewProps<T>) {
  const field = element[label];
  const reviewLabel = String(label);
  const diff = fieldDiff?.[label];
  const readField = String(element[label]);

  return (
    <>
      <ReviewLabel>{reviewLabel}</ReviewLabel>
      {typeof element[label] === "object" ? (
        <>
          {Object.keys(field as object)
            .filter(k => k !== "id")
            .map((k: keyof object) => (
              <ObjectFieldReview<typeof field>
                key={k}
                label={k}
                element={field}
                fieldDiff={fieldDiff ? fieldDiff[k] : null}
              />
            ))}
        </>
      ) : (
        <TextField>
          {diff && <ModifiedField>{String(diff)}</ModifiedField>}
          <Field>{readField}</Field>
        </TextField>
      )}
    </>
  );
}

interface FieldReviewProps {
  label: string;
  value: string;
  fieldDiff?: string | null;
  showRedacted?: boolean;
}

export function FieldReview({ label, value, fieldDiff, showRedacted = false }: FieldReviewProps) {
  return (
    <>
      <ReviewLabel>{label}</ReviewLabel>
      <TextField>
        {fieldDiff && <ModifiedField>{fieldDiff}</ModifiedField>}
        <Field>{!showRedacted ? value : <RedactedTag>redacted</RedactedTag>}</Field>
      </TextField>
    </>
  );
}
export type ReviewFieldType = "modified" | "override" | "unmodified" | "default" | "unset";

const FieldBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
const BadgeTextField = styled(TextField)`
  padding: 0;
`;
const BadgeField = styled(Field)`
  margin: 0;
`;

export const redactedStyles = css`
  background-color: ${({ theme }) => theme.backgroundLight};
  font-style: italic;
`;

const RedactedTag = styled(ReadOnly)`
  ${redactedStyles}
  text-transform: lowercase;
`;

const ModifiedBadge = styled(ReadOnly)`
  background-color: ${({ theme }) => theme.lightblue};
  color: white;
`;

const UnsetBadge = styled(RedactedTag)`
  color: white;
  background-color: ${({ theme }) => theme.primaryLight};
`;
interface BadgeFieldReviewProps extends Omit<FieldReviewProps, "value"> {
  reviewType: ReviewFieldType;
  value: ValueType | undefined;
  showRedacted: boolean;
}

export function BadgeFieldReview({
  label,
  value,
  fieldDiff,
  reviewType,
  showRedacted
}: BadgeFieldReviewProps) {
  return (
    <>
      <ReviewLabel>{label}</ReviewLabel>
      <BadgeTextField>
        {fieldDiff != null && <ModifiedField>{fieldDiff}</ModifiedField>}
        <FieldBadgeWrapper>
          {showRedacted ? (
            <RedactedTag>redacted</RedactedTag>
          ) : (
            <>
              {value !== undefined && (
                <BadgeField>
                  {typeof value !== "object" ? String(value) : JSON.stringify(value)}
                </BadgeField>
              )}
            </>
          )}
          <ReviewBadge type={reviewType} />
        </FieldBadgeWrapper>
      </BadgeTextField>
    </>
  );
}

function ReviewBadge({ type }: { type: ReviewFieldType }) {
  switch (type) {
    case "modified":
    case "override":
      return <ModifiedBadge>{type}</ModifiedBadge>;
    case "unset":
      return <UnsetBadge>{type}</UnsetBadge>;
    default:
      return null;
  }
}
