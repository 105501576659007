import "react-loading-skeleton/dist/skeleton.css";

import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Templates } from "@src/ServicesDashboard/Publisher//Templates/Templates";
import { Editor } from "@src/ServicesDashboard/Publisher/Editor/Editor";
import { PublishTab } from "@src/ServicesDashboard/Publisher/Publish/Publish";
import { TemplateInformation } from "@src/ServicesDashboard/Publisher/Templates/TemplateInformation";

const PublisherPage = lazy(() => import("@src/ServicesDashboard/Publisher/PublisherPage"));

export function SoftwarePublisherRoutes() {
  return (
    <Routes>
      <Route index element={<Navigate to="publisher" />} />

      <Route path="publisher">
        <Route element={<PublisherPage />}>
          <Route index element={<Navigate to="templates" />} />
          <Route path="templates" element={<Templates />}>
            <Route path=":name" element={<TemplateInformation />} />
          </Route>
          <Route path="editor" element={<Editor />} />
          <Route path="publish" element={<PublishTab />} />
        </Route>
      </Route>
    </Routes>
  );
}
