import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import { Grid, Item, ItemLine, Provider } from "./ChartListStyles";
import { ChartName } from "./ChartStyles";

const ChartItemName = styled(ChartName)`
  padding-right: 26px;
`;

export function ChartListItemSkeleton() {
  return (
    <Item $selected={false}>
      <ItemLine>
        <Skeleton height={30} width={80} />
      </ItemLine>
      <Grid>
        <div>
          <ChartItemName>
            <Skeleton count={1.6} />
          </ChartItemName>
          <Provider>
            <Skeleton width="40%" />
          </Provider>
        </div>
        <div>
          <ChartName>
            <Skeleton width="70%" />
          </ChartName>
          <Skeleton height={20} />
        </div>
      </Grid>
    </Item>
  );
}
