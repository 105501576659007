import styled from "styled-components";

export const ChartWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.border};
  box-shadow: 2px 2px 3px 0px rgba(204, 204, 204, 0.5);
  transition: 0.3s transform, 0.3s box-shadow;

  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 3px 3px 5px 1px rgba(204, 204, 204, 0.5);
  }

  background: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;
