import { Namespace } from "@src/Generated/permissions/namespace";
import { Permit } from "@src/Generated/permissions/permit";

import { usePermissionCheck } from "../permissionsCheck";

export function useCanViewFederation(orgId: string | null) {
  return usePermissionCheck({
    objectType: Namespace.Org,
    objectId: orgId,
    relation: Permit.ViewFederation
  });
}
