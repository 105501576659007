import { useQuery } from "@apollo/client";
import { FetchParentSiteQuery, FetchParentSiteQueryVariables } from "@src/Generated/graphql";
import { useOrgInfo } from "@src/Hooks/orgInfo";
import { notEmpty } from "@src/Utils/filterEmpty";

import FetchParentSite from "./FetchParentSite.graphql";

export function useParentSiteInfo(parentSiteId: string) {
  const { orgId, orgName } = useOrgInfo(true);
  const isOrg = orgId === parentSiteId;
  const { data: siteData } = useQuery<FetchParentSiteQuery, FetchParentSiteQueryVariables>(
    FetchParentSite,
    {
      variables: { id: parentSiteId },
      skip: !parentSiteId || isOrg
    }
  );

  const parentSiteName = isOrg ? orgName : siteData?.site?.displayName;

  const rootUrl = `/app/${orgId}/infrastructure`;
  const linkUrl = isOrg ? `${rootUrl}` : `${rootUrl}/site/${parentSiteId}`;

  return {
    parentSiteName,
    ancestors: (siteData?.site?.ancestors || []).filter(notEmpty),
    linkUrl: linkUrl + "/overview"
  };
}
