import { FormikProps } from "formik";
import { useCallback, useEffect, useReducer, useRef } from "react";
import { NavigateFunction, useOutletContext } from "react-router-dom";
import styled from "styled-components";

import { useQuery } from "@apollo/client";
import { ConfigWrapper } from "@src/Components/ChartSelector/ConfigStyles";
import { EditorStatus } from "@src/Components/EmbeddedEditor/editorStatus";
import { NoCharts } from "@src/Components/NoCharts";
import { FetchTemplateChartQuery, FetchTemplateChartQueryVariables } from "@src/Generated/graphql";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { softwarePublisher } from "@src/Hooks/Dashboard/dashboards";

import { editorPublisherReducer } from "../editorPublisherReducer";
import FetchTemplateChart from "../FetchTemplateChart.graphql";
import { emptyTemplateForm } from "../PublisherPage";
import { BlockPublisherForm } from "../serialise";
import { BlockConfigPanel } from "./BlockConfigPanel";
import { SelectedTemplateBlock } from "./SelectedTemplateBlock";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(1250px, 5fr);
  flex-grow: 1;
`;

interface EditorProps extends FormikProps<BlockPublisherForm> {
  navigate: NavigateFunction;
}

function useEditorProps() {
  return useOutletContext<EditorProps>();
}

export function Editor() {
  const orgCtx = useOrgCtx();
  const { navigate, ...formikProps } = useEditorProps();
  const { values: newBlock, setValues } = formikProps;

  const hasRemovedTemplate = useRef(false);
  const removeTemplate = useCallback(() => {
    setValues(emptyTemplateForm);
    hasRemovedTemplate.current = true;
  }, [setValues]);
  useEffect(() => {
    if (hasRemovedTemplate.current && emptyTemplateForm === newBlock) {
      navigate("templates");
    }
  }, [navigate, newBlock]);

  const { templateName, selectedVersion, overrides, values } = newBlock;

  const { data, loading, refetch } = useQuery<
    FetchTemplateChartQuery,
    FetchTemplateChartQueryVariables
  >(FetchTemplateChart, {
    variables: {
      name: templateName,
      version: selectedVersion
    },
    skip: !templateName || !selectedVersion,
    notifyOnNetworkStatusChange: true
  });

  const template = data?.templateChart ?? null;

  const [editorState, editorDispatch] = useReducer(editorPublisherReducer, {
    overrides: {
      status: EditorStatus.Default,
      current: overrides,
      original: "",
      saved: overrides
    },
    values: {
      status: EditorStatus.Default,
      current: values,
      original: "",
      saved: values
    }
  });

  return (
    <Wrapper>
      <SelectedTemplateBlock
        block={newBlock}
        editorState={editorState}
        editorDispatch={editorDispatch}
        removeTemplate={removeTemplate}
        refetchTemplate={refetch}
        templateChart={template}
        loading={loading}
        {...formikProps}
      />
      {template || loading ? (
        <BlockConfigPanel
          editorState={editorState}
          editorDispatch={editorDispatch}
          originalTemplate={template}
          loading={loading}
          {...formikProps}
        />
      ) : (
        <ConfigWrapper>
          <NoCharts
            link={softwarePublisher.route({ org: orgCtx ?? null, subRoute: "publisher/templates" })}
            linkText="templates tab"
            entity="template"
          />
        </ConfigWrapper>
      )}
    </Wrapper>
  );
}
