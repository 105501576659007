import PublisherIcon from "@img/cloud-arrow-up-solid.svg";
import { NavIcons } from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { softwarePublisher } from "@src/Hooks/Dashboard/dashboards";
import { useTranslation } from "react-i18next";

export function SoftwarePublisherNavbar() {
  const orgCtx = useOrgCtx();
  const { t } = useTranslation("nav");

  const rootPath = softwarePublisher.route({ org: orgCtx ?? null });
  return (
    <>
      <NavIcons>
        <NavItem
          linkTo={rootPath + "/publisher"}
          label={t("publisher.publisher")}
          icon={PublisherIcon}
        />
      </NavIcons>
    </>
  );
}
