import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import InventoryIcon from "@img/clipboard-list-solid.svg";
import ComputeResourcesIcon from "@img/compute.svg";
import OverviewIcon from "@img/map.svg";
import ProvisionIcon from "@img/provision.svg";
import ServicesIcon from "@img/services.svg";
import { NavIcons } from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { infrastructureDashboard } from "@src/Hooks/Dashboard/dashboards";
import { useCanReadProvision } from "@src/Hooks/Permissions/provision";

export function InfrastructureNavbar() {
  const orgCtx = useOrgCtx() ?? null;
  const { pathname } = useLocation();
  const { t } = useTranslation("nav");

  const siteCtx = extractSiteCtx(pathname);

  const rootPath = infrastructureDashboard.route({ org: orgCtx, site: siteCtx });

  const { allowed: canViewProvision } = useCanReadProvision();

  return (
    <NavIcons>
      <NavItem
        linkTo={infrastructureDashboard.route({ org: orgCtx }) + "/overview"}
        label={t("infra.overview")}
        icon={OverviewIcon}
      />
      <NavItem linkTo={rootPath + "/inventory"} label={t("infra.inventory")} icon={InventoryIcon} />

      <NavItem
        linkTo={rootPath + "/compute-resources"}
        label={t("infra.compute")}
        icon={ComputeResourcesIcon}
      />
      {canViewProvision && (
        <NavItem
          linkTo={rootPath + "/provision"}
          label={t("infra.provisioning")}
          icon={ProvisionIcon}
        />
      )}
      <NavItem linkTo={rootPath + "/services"} label={t("infra.services")} icon={ServicesIcon} />
    </NavIcons>
  );
}

function extractSiteCtx(path: string) {
  const matchPath = /site\/(?<siteCtx>[^\/]+)/;

  const match = path.match(matchPath);

  return match?.groups?.siteCtx;
}
