import CustomizationIcon from "@img/customizations.svg";
import GroupsIcon from "@img/groups.svg";
import OrgIcon from "@img/org.svg";
import UserIcon from "@img/user-filled.svg";
import { NavIcons } from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { globalSettings } from "@src/Hooks/Dashboard/dashboards";
import { useCanReadEnvBranding } from "@src/Hooks/Permissions/envBranding";
import { useTranslation } from "react-i18next";

export function GlobalSettingsNavbar() {
  const orgCtx = useOrgCtx() ?? null;
  const rootPath = globalSettings.route({ org: orgCtx });
  const { t } = useTranslation("nav");

  const { allowed: canReadCustomizations } = useCanReadEnvBranding();

  if (!orgCtx) return <OrgLessNavbar />;
  return (
    <NavIcons>
      {canReadCustomizations && (
        <NavItem
          linkTo={rootPath + "/customizations"}
          label={t("global-settings.customization")}
          icon={CustomizationIcon}
        />
      )}
      <NavItem linkTo={rootPath + "/users"} label={t("global-settings.users")} icon={UserIcon} />
      <NavItem
        linkTo={rootPath + "/groups"}
        label={t("global-settings.groups")}
        icon={GroupsIcon}
      />
      <NavItem
        linkTo={rootPath + "/organizations"}
        label={t("global-settings.organizations")}
        icon={OrgIcon}
      />
    </NavIcons>
  );
}

function OrgLessNavbar() {
  const { t } = useTranslation("nav");
  return (
    <NavIcons>
      <NavItem
        linkTo={"/app/global-settings/organizations"}
        label={t("global-settings.organizations")}
        icon={OrgIcon}
      />
    </NavIcons>
  );
}
