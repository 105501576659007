import { Namespace } from "@src/Generated/permissions/namespace";
import { Permit } from "@src/Generated/permissions/permit";

import { usePermissionCheck } from "../permissionsCheck";

export function useCanReadSim(coreId: string | null) {
  return usePermissionCheck({
    objectType: Namespace.Core,
    objectId: coreId,
    relation: Permit.ReadSim
  });
}
export function useCanCreateSim(coreId: string | null) {
  return usePermissionCheck({
    objectType: Namespace.Core,
    objectId: coreId,
    relation: Permit.CreateSim
  });
}

export function useCanWriteSim(coreId: string | null) {
  return usePermissionCheck({
    objectType: Namespace.Core,
    objectId: coreId,
    relation: Permit.WriteSim
  });
}

export function useCanDeleteSim(coreId: string | null) {
  return usePermissionCheck({
    objectType: Namespace.Core,
    objectId: coreId,
    relation: Permit.DeleteSim
  });
}
