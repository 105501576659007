import { Layer, LayerProps, MapLayerMouseEvent, Source } from "react-map-gl";

import { LatLng, TagInput } from "@src/Generated/graphql";
import { Bounds } from "@src/Map/bounds";
import { useBbox } from "@src/Map/Hooks/bbox";
import { MapState } from "@src/Map/mapReducer";

import { useDebouncedDevicesQuery } from "./DevicesQuery";

export const SOURCE_DEVICES = "devices";
const LAYER_DEVICES = "device-markers-devices";

const layer: LayerProps = {
  id: LAYER_DEVICES,
  type: "symbol",
  paint: {
    "text-translate": [-15, 0]
  },
  layout: {
    "icon-image": "marker-11",
    "text-field": ["get", "title"],
    "text-anchor": "right"
  }
};

interface DeviceMarkersProps {
  mapState: MapState;
  tags?: TagInput[];
}

export function DeviceMarkers({ mapState, tags }: DeviceMarkersProps) {
  const bbox = useBbox(mapState) as Bounds;

  const data = useDebouncedDevicesQuery({
    skip: !bbox,
    tags: tags || [],
    bbox
  });

  const geojson: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: "FeatureCollection",
    features: (data?.devices || [])
      .filter(d => d.position)
      .map(({ id, position, displayName }) => {
        const { lng, lat } = position as LatLng;
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [lng, lat]
          },
          properties: {
            id,
            title: displayName
          }
        };
      })
  };

  return (
    <Source id={SOURCE_DEVICES} type="geojson" data={geojson}>
      <Layer {...layer} />
    </Source>
  );
}

export function onDeviceMarkersClick(e: MapLayerMouseEvent) {
  return e?.features?.find(f => f.source === SOURCE_DEVICES)?.properties;
}
