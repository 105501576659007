export enum PublisherEditingSection {
  Values = "Block values",
  Descriptor = "Block descriptor",
  Overrides = "Block overrides"
}

export enum DesignerEditingSection {
  Values = "Block values",
  Descriptor = "Block descriptor"
}
