import { ChangeEvent, KeyboardEvent, ReactNode, useCallback } from "react";
import styled from "styled-components";

import { searchTextPreview } from "@src/Hooks/marketplaceAux";
import { useToggle } from "@src/Hooks/toggle";

import { Clickable } from "../Buttons/Clickable";
import { PrimaryButton } from "../Buttons/Primary";
import { DropdownMenuItemWrapper, Menu, MenuAnchor } from "../DropdownMenu";
import { Input } from "../Input/Input";

const BarSettingWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: minmax(200px, 60%) auto;
  gap: 16px;
`;

const FilterWrapper = styled.div`
  width: 100%;
`;

const SearchBarWrapper = styled.div`
  display: flex;
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const SearchInput = styled(Input)`
  padding: 8px;
  height: auto;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
`;

const DeleteSearchButton = styled(Clickable)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto 0;
  font-size: 18px;
  font-weight: 600;
  opacity: 0.8;
  &:hover {
    color: ${({ theme }) => theme.failure};
  }
`;

const AddFilterButton = styled(PrimaryButton)`
  width: auto;
  height: auto;
  padding: 2px 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`;

const FiltersMenu = styled(Menu)`
  border-top: none;
`;

interface ChartSearchBarProps {
  inputFilter: string;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  deleteInputText: () => void;
  addFilter: (value: string) => void;
  entity: string;
  children?: ReactNode;
}

export function FilterSearchBar({
  inputFilter,
  onInputChange,
  deleteInputText,
  addFilter,
  entity,
  children
}: ChartSearchBarProps) {
  const { state: isFocused, on: focus, off: blur } = useToggle();

  const handlePressEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== "Enter") return;
      addFilter(inputFilter);
    },
    [addFilter, inputFilter]
  );

  return (
    <BarSettingWrapper>
      <FilterWrapper>
        <SearchBarWrapper>
          <InputWrapper>
            <SearchInput
              title="searchInput"
              placeholder={`Search for ${entity} by applying filters...`}
              value={inputFilter}
              onChange={onInputChange}
              onFocus={focus}
              onBlur={blur}
              onKeyDown={handlePressEnter}
            />
            {inputFilter.length > 0 && (
              <DeleteSearchButton onClick={deleteInputText}>✕</DeleteSearchButton>
            )}
          </InputWrapper>
          <AddFilterButton onClick={() => addFilter(inputFilter)}>Apply</AddFilterButton>
        </SearchBarWrapper>
        <MenuAnchor>
          {inputFilter.length > 0 && isFocused && (
            <FiltersMenu>
              <DropdownMenuItemWrapper>
                Searching for: {searchTextPreview(inputFilter)}
              </DropdownMenuItemWrapper>
            </FiltersMenu>
          )}
        </MenuAnchor>
      </FilterWrapper>

      {children}
    </BarSettingWrapper>
  );
}
