import "react-resizable/css/styles.css";

import styled from "styled-components";

import { useQuery } from "@apollo/client";
import ArrowUpDD from "@img/arrow_light_up.svg";
import {
  BottomPanel,
  Cross,
  PanelTitle,
  PanelTitleActions,
  PanelTopWrapper,
  PanelWrapper
} from "@src/Components/BottomPanel/BottomPanel";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { FetchPanelLogsQuery, FetchPanelLogsQueryVariables } from "@src/Generated/graphql";
import { useFilterByFields } from "@src/Hooks/filterByFields";
import { useToggle } from "@src/Hooks/toggle";
import { notEmpty } from "@src/Utils/filterEmpty";

import FetchPanelLogs from "./FetchPanelLogs.graphql";
import { LogsFilter, logTypeFilters } from "./LogsFilter";
import { PanelLogsTable } from "./PanelLogsTable";

const PanelButtonWrapper = styled.div`
  width: fit-content;
  position: absolute;
  bottom: 0;
  right: 5%;
`;

const OpenPanelButton = styled(Clickable)`
  background-color: white;
  font-size: medium;
  padding: 10px 20px;
  border-radius: 4px 4px 0 0;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
  gap: 12px;
  white-space: nowrap;
`;

export function LogsPanel() {
  const { state, on: open, off: close } = useToggle();

  return (
    <>
      {!state && (
        <PanelButtonWrapper>
          <OpenPanelButton onClick={open}>
            Audit Logs <ArrowUpDD height={20} width={20} />
          </OpenPanelButton>
        </PanelButtonWrapper>
      )}
      <BottomPanel show={state} closePanel={close}>
        <LogsPanelInner close={close} />
      </BottomPanel>
    </>
  );
}

export const pollInterval = 10000;
interface LogsPanelInnerProps {
  close: () => void;
}

function LogsPanelInner({ close }: LogsPanelInnerProps) {
  const { selectedFilters, toggleFilterTag } = useFilterByFields(logTypeFilters);

  const { data, loading } = useQuery<FetchPanelLogsQuery, FetchPanelLogsQueryVariables>(
    FetchPanelLogs,
    {
      errorPolicy: "all",
      fetchPolicy: "network-only",
      pollInterval: pollInterval,
      variables: {
        startTime: "now-10m",
        endTime: "now",
        pageSize: 10,
        ...(selectedFilters.length === logTypeFilters.length
          ? null
          : {
              eventTypes: selectedFilters
            })
      }
    }
  );

  const logs = (data?.auditLogs?.edges || []).map(l => l?.node).filter(notEmpty);

  return (
    <PanelWrapper>
      <PanelTopWrapper>
        <PanelTitleActions>
          <PanelTitle>Recent Logs</PanelTitle>
          <Cross title="close" onClick={close}>
            ✕
          </Cross>
        </PanelTitleActions>
        <LogsFilter
          selectedFilters={selectedFilters}
          toggleFilterTag={toggleFilterTag}
          filtersPadding="6px 10px"
        />
      </PanelTopWrapper>
      <PanelLogsTable logs={logs} loading={loading} />
    </PanelWrapper>
  );
}
