import { GraphQLError } from "graphql";
import styled from "styled-components";

import { H3 } from "./Text";

export const ErrorContainer = styled.div`
  color: ${props => props.theme.error};
  margin-bottom: 2em;
`;

export type SubmitErrors = readonly GraphQLError[] | undefined;

interface GraphQLErrorsProps {
  submitErrors: SubmitErrors;
  titleMessage: string;
}

export default function GraphQLErrors({ submitErrors, titleMessage }: GraphQLErrorsProps) {
  return (
    <ErrorContainer>
      <H3>{titleMessage}</H3>
      <p>Please review the details and try again:</p>
      <ul>
        {(submitErrors || []).map((err, i) => (
          <li key={i}>{err.message}</li>
        ))}
      </ul>
    </ErrorContainer>
  );
}
