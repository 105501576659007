import { cloudManager } from "@src/Hooks/Dashboard/dashboards";

import { routesRedirect } from "./complexRoutesRedirect";

export function cloudManagerRedirect(path: string, org: string) {
  const oldRoute = `/app/${org}/mec`;
  const rootRoute = cloudManager.route({ org });

  if (path === `${oldRoute}/operate`) {
    return rootRoute + "/deployed-apps";
  }
  if (path.includes(`${oldRoute}/operate/deployed-apps`)) {
    const shortenedPath = path.replace("/operate", "");
    return routesRedirect(shortenedPath, `${rootRoute}`);
  }

  if (
    path.includes("/catalog") ||
    path.includes("/cloud-sites") ||
    path.includes("/tenants") ||
    path.includes("/observe")
  ) {
    return routesRedirect(path, `${rootRoute}`);
  }

  if (path.includes("/services/federation")) {
    return routesRedirect(path, rootRoute);
  }

  return rootRoute;
}
