import { Dispatch, useCallback } from "react";
import styled from "styled-components";

import Config from "@img/configure.svg";
import { useToggle } from "@src/Hooks/toggle";
import {
  FilterEntitySettings,
  MarketplaceAction
} from "@src/SharedViews/ServiceDesigner/Marketplace/marketplaceReducer";

import { Clickable } from "../Buttons/Clickable";
import { ElementLabel } from "../Label";
import { ChartFilterCheckbox } from "./ChartFilterCheckbox";

const SearchSettingsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const FilterSettingsButton = styled(Clickable)`
  display: flex;
  align-items: center;
  padding: 0;
  padding-right: 4px;
  &:hover {
    & svg {
      color: ${({ theme }) => theme.primary};
    }
  }
`;

const FilterSettings = styled(Config)`
  height: 32px;
  color: #999;
`;

const TitleCheckboxWrapper = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  padding-top: 4px;
  padding-left: 4px;
  border-left: ${({ theme }) => `1px solid ${theme.grey}`};
`;

const SlidersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
  padding-top: 2px;
  margin-left: 10px;
`;

interface ChartSettingsProps {
  dispatch: Dispatch<MarketplaceAction>;
  includeAllTexts: boolean;
  includeAllCategories: boolean;
}

export function ChartSearchSettings({
  dispatch,
  includeAllCategories,
  includeAllTexts
}: ChartSettingsProps) {
  const { state: showSettings, toggle: toggleSettings } = useToggle();

  const toggleCheckbox = useCallback(
    (settings: FilterEntitySettings) => {
      dispatch({ type: "toggleSetting", field: settings });
    },
    [dispatch]
  );
  return (
    <SearchSettingsWrapper>
      <FilterSettingsButton title="search settings" onClick={toggleSettings}>
        <FilterSettings />
      </FilterSettingsButton>
      {showSettings && (
        <TitleCheckboxWrapper>
          <ElementLabel>Search Settings: </ElementLabel>
          <SlidersWrapper>
            <ChartFilterCheckbox
              label="Search terms"
              checked={includeAllTexts}
              toggleCheckbox={() => toggleCheckbox("includeAllTexts")}
            />
            <ChartFilterCheckbox
              label="Categories"
              checked={includeAllCategories}
              toggleCheckbox={() => toggleCheckbox("includeAllCategories")}
            />
          </SlidersWrapper>
        </TitleCheckboxWrapper>
      )}
    </SearchSettingsWrapper>
  );
}
