import { useCallback, useRef } from "react";
import styled, { css } from "styled-components";
import { useOnClickOutside } from "usehooks-ts";

import { boxShadow } from "@src/Components/Table/Table";
import { editConfigOptionsZIndex } from "@src/Components/zIndexValues";
import { useToggle } from "@src/Hooks/toggle";

const spanCss = css`
  display: block;
  width: 5px;
  background-color: black;
  height: 5px;
  border-radius: 50%;
`;

const Options = styled.button<{ $open: boolean }>`
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: ${({ $open }) => ($open ? "#ccc" : "transparent")};

  &:hover {
    background-color: #ccc;
  }

  span {
    align-self: center;
    position: relative;
    ${spanCss}

    &::after,
   &::before {
      position: absolute;
      content: "";
      ${spanCss};
    }

    &::after {
      bottom: 7px;
    }

    &::before {
      top: 7px;
    }
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  width: fit-content;
  background-color: white;
  z-index: ${editConfigOptionsZIndex};
  border: 1px solid #ccc;
  ${boxShadow}
`;

export type Action = {
  displayName: string;
  action: () => void;
  disabled: boolean;
};

interface ConfigurationOptionsProps {
  actions: Action[];
}

export function ConfigurationOptions({ actions }: ConfigurationOptionsProps) {
  const { state: isOpen, toggle, off: close } = useToggle();

  const ref = useRef(null);
  useOnClickOutside(ref, close);

  return (
    <>
      <Options $open={isOpen} onClick={toggle} title="options">
        <span />
      </Options>
      {isOpen && (
        <Wrapper ref={ref}>
          {actions.map(a => (
            <Option action={a} close={close} key={a.displayName} />
          ))}
        </Wrapper>
      )}
    </>
  );
}

const ActionLine = styled.button`
  cursor: pointer;
  display: block;
  width: 100%;
  line-height: 30px;
  text-align: start;
  padding-left: 20px;
  padding-right: 30px;
  text-transform: capitalize;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ccc;

  &:hover {
    background-color: #ccc;
  }

  &:last-child {
    border: none;
  }
`;

interface OptionProps {
  action: Action;
  close: () => void;
}

function Option({ action, close }: OptionProps) {
  const { action: click, disabled, displayName } = action;

  const onClick = useCallback(() => {
    click();
    close();
  }, [click, close]);

  return (
    <ActionLine disabled={disabled} onClick={onClick}>
      {displayName}
    </ActionLine>
  );
}
