import { ChangeEvent, useCallback } from "react";
import styled from "styled-components";

import {
  InnerFirstLine,
  ReloadCharts,
  ReloadClickable,
  Select,
  Title,
  TotalCharts
} from "@src/Components/ChartSelector/ChartSelectorList";
import { Skeleton } from "@src/Components/Skeleton";
import { MarketplaceProps, sortOptions, viewOptions } from "@src/Hooks/marketplace";
import { splitSearchString } from "@src/Hooks/marketplaceAux";
import { MarketplaceFilterType } from "@src/SharedViews/ServiceDesigner/Marketplace/marketplaceReducer";

import { FilterEntity } from "../Filters/filterEntity";
import { FilterSearchBar } from "../Filters/FilterSearchBar";
import { SearchFilters } from "../Filters/SearchFilters";
import { ChartSearchSettings } from "./ChartSearchSettings";

const Wrapper = styled.div`
  margin-top: 24px;
`;

interface ChartsDisplayProps<T> extends MarketplaceProps<T> {
  chartType: string;
}

export function ChartsDisplay<T>(marketplaceProps: ChartsDisplayProps<T>) {
  const {
    filteredBlocks,
    allBlocksNumber,
    filters,
    inputFilter,
    includeAllTexts,
    includeAllCategories,
    dispatch,
    refetch,
    loading,
    chartType,
    view,
    sort
  } = marketplaceProps;

  const swapView = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => dispatch({ type: "swapView", value: e.target.value }),
    [dispatch]
  );

  const swapSort = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => dispatch({ type: "swapSort", value: e.target.value }),
    [dispatch]
  );

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: "changeInputFilter", value: e.currentTarget.value });
    },
    [dispatch]
  );

  const deleteInputText = useCallback(() => {
    dispatch({ type: "changeInputFilter", value: "" });
  }, [dispatch]);

  const addFilter = useCallback(
    (value: string) => {
      if (value) {
        const filters = splitSearchString(value);
        filters.forEach(v => {
          dispatch({ type: "selectFilter", filter: { type: "search term", value: v } });
        });
      }
    },
    [dispatch]
  );

  const removeFilter = useCallback(
    (f: FilterEntity<MarketplaceFilterType, string>) => {
      dispatch({ type: "removeFilter", filter: f });
    },
    [dispatch]
  );

  const clearFilters = useCallback(() => {
    dispatch({ type: "cleanAllFilters" });
  }, [dispatch]);

  return (
    <Wrapper>
      <FilterSearchBar
        entity="chart"
        onInputChange={onInputChange}
        deleteInputText={deleteInputText}
        addFilter={addFilter}
        inputFilter={inputFilter}
      >
        <ChartSearchSettings
          dispatch={dispatch}
          includeAllTexts={includeAllTexts}
          includeAllCategories={includeAllCategories}
        />
      </FilterSearchBar>

      <SearchFilters
        removeFilter={removeFilter}
        clearFilters={clearFilters}
        filters={filters}
        renderFilter={renderFilter}
        renderKey={renderKey}
      />

      <InnerFirstLine>
        <TotalCharts>
          <Title>
            {!loading ? (
              `viewing ${filteredBlocks.length} out of ${allBlocksNumber} ${chartType} charts`
            ) : (
              <Skeleton width={150} />
            )}
          </Title>
        </TotalCharts>
        <Title>view</Title>
        <Select name="view" id="view" value={view} onChange={swapView}>
          {Object.entries(viewOptions).map(([key, value]) => (
            <option value={value} key={key}>
              {key}
            </option>
          ))}
        </Select>
        <Title>sort</Title>
        <Select name="sort" id="sort" value={sort} onChange={swapSort}>
          {Object.entries(sortOptions).map(([key, value]) => (
            <option value={value} key={key}>
              {key}
            </option>
          ))}
        </Select>
        <ReloadClickable onClick={() => refetch()} disabled={loading}>
          refresh <ReloadCharts />
        </ReloadClickable>
      </InnerFirstLine>
    </Wrapper>
  );
}

function renderFilter(filter: string) {
  return filter;
}
function renderKey(filter: string, type: string) {
  return `${filter}-${type}`;
}
