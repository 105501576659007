import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { useSession } from "@src/Session";

export function useLocalStorageKey(key: string) {
  const { session } = useSession();
  const userId = session?.identity?.id;
  const orgId = useOrgCtx();
  return userId && orgId ? [key, userId, orgId].join(".") : null;
}

export function getLSItemWithMigration(
  localStorage: Storage,
  key: string,
  emptyValues: object
): string {
  const oldKey = key.split(".")[0];
  const stringifiedEmptyValues = JSON.stringify(emptyValues);

  const value = localStorage.getItem(key);
  if (value && validateLSValue(value, emptyValues)) {
    return value;
  }

  const previousValue = localStorage.getItem(oldKey);
  if (previousValue && validateLSValue(previousValue, emptyValues)) {
    localStorage.setItem(key, previousValue);
    localStorage.removeItem(oldKey);
    return previousValue;
  }

  return stringifiedEmptyValues;
}

function validateLSValue(value: string, emptyValues: object) {
  const parsedValue = JSON.parse(value);
  if (typeof parsedValue !== "object" || !parsedValue || !emptyValues) return false;

  const valueKeys = Object.keys(parsedValue).sort();
  const validationKeys = Object.keys(emptyValues).sort();

  return JSON.stringify(valueKeys) === JSON.stringify(validationKeys);
}
