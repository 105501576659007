import { ReactNode } from "react";

import {
  ChartDescription,
  ChartName,
  ChartProvider,
  FirstLine,
  Image,
  Instances,
  NoProvider
} from "@src/Components/ChartSelector/ChartStyles";
import { ChartWrapper } from "@src/Components/Grid/ChartWrapper";
import { Skeleton } from "@src/Components/Skeleton";
import { BlockChart, TemplateChart } from "@src/Generated/graphql";

type ChartProps = {
  chart: BlockChart | TemplateChart;
  view: number;
  children: ReactNode;
  openChartInformation: (name: string, version: string) => void;
};

export function Chart({ view, children, openChartInformation, chart }: ChartProps) {
  const { name, version, description, displayName, logoUrl, vendor } = chart;

  return (
    <ChartWrapper
      title={`${displayName} block`}
      onClick={() => openChartInformation(name, version)}
    >
      <FirstLine $isGrid={view == 1}>
        <Image src={logoUrl ?? undefined} alt={`${vendor || name} logo`} />
        <div>
          <ChartName>{displayName}</ChartName>
          <ChartProvider>by {vendor || <NoProvider>no vendor</NoProvider>}</ChartProvider>
        </div>
      </FirstLine>
      <ChartDescription>{description}</ChartDescription>
      {children}
    </ChartWrapper>
  );
}

export function ChartSkeleton({ elements }: { elements: number }) {
  const charts = [...Array(elements).keys()];
  return (
    <>
      {charts.map(i => (
        <ChartWrapper key={i}>
          <FirstLine $isGrid={true}>
            <Skeleton height={30} width={75} />
            <div>
              <ChartName>
                <Skeleton width={180} />
              </ChartName>
              <ChartProvider>
                <Skeleton width={100} />
              </ChartProvider>
            </div>
          </FirstLine>
          <ChartDescription>
            <Skeleton count={10} />
          </ChartDescription>
          <Instances>
            <Skeleton height={30} width={60} />
          </Instances>
        </ChartWrapper>
      ))}
    </>
  );
}
