export function validateOrgCtx(orgCtx: string) {
  const match = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-f-A-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
  const isValid = orgCtx.match(match);

  return isValid;
}

export function redirectValidOrgCtx(path: string, defaultOrg: string) {
  const rootRoute = `/app/${defaultOrg}`;
  const matchPath = /^\/app\/([^\/]+\/){1}(?<path>.*)/;
  const match = path.match(matchPath);

  if (match && match?.groups?.path) {
    const path = match.groups.path;
    return `${rootRoute}/${path}`;
  } else {
    return rootRoute;
  }
}
