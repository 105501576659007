// Code generated by ts_objectpolicy_uuid_const_gen.sh

export enum GlobalObjectPolicyID {
  APIManagementDashboard = "d8278f3a-8f27-58cb-b334-da5da19ace32",
  BlockChart = "830f252b-e997-5ab6-8b79-0957d9a8f847",
  Chain = "64d6a676-6054-5059-9cc7-829023f076e8",
  ChartReleaseShell = "25bf6607-0c98-5edd-bca8-deccf9f9962e",
  CloudManagerDashboard = "fa3a40f1-37da-5e99-a000-5d0e04953ad0",
  CloudResource = "a8e5f26d-9ec7-5026-b2fa-57bcbd3e1e18",
  Cloudlet = "0e0fa297-51bd-5a0e-b84c-0b04ccb03aa3",
  CloudletShell = "27a0cb6f-9f0f-5eb6-bc8d-4b9ef7d309c5",
  ComponentVersionsPage = "f9f790e5-a00c-5c0e-9c03-9200105d2450",
  ComputeResource = "b7ae30ea-30ee-53e2-a5aa-f9893eee4b88",
  ConfigurationManagement = "d72640f8-c378-5085-8044-d31fad4841b5",
  Core = "a6487220-2382-5ae7-9d9a-3b1cfacbe3ee",
  CoreManager = "42034a71-e559-52fd-a6a3-e515144d2c34",
  EnvBranding = "4d71a345-23e3-5bc4-bc8a-4c8569b74114",
  FederationGuest = "1d9e8227-da4c-5baf-b63d-c1cee70d4b53",
  FederationHost = "f8919b21-1563-5747-a0b0-f1aabe776dac",
  GlobalSettingsDashboard = "d609eedc-efb8-5eec-90d7-4069e8191157",
  Gnb = "2948dc04-64fa-5113-9d6e-ec2f6eae8ec7",
  IPPool = "34aacdc5-3d7d-545d-968c-7153c70889d5",
  IPPoolMapping = "e9508bc5-fe74-5178-84e7-03665e3412d8",
  InfrastructureDashboard = "708dcdfd-4080-5578-b362-7ddcd1b47800",
  LandingPage = "1fc5e727-6a1c-585c-aed4-b4584004f297",
  Logs = "4a839118-4410-562a-b4d3-9d89f69bfe02",
  MecChain = "31519c44-3c15-549e-ad14-a8cbc8486a87",
  MecSite = "4588ce5d-5ae0-5c7b-9fbb-facff87daf92",
  MobileNetworksDashboard = "585dee1e-1d9a-5532-a551-3be4fff159ac",
  Network = "e0fe9bbe-f0cc-590c-9218-d30a6d3233a8",
  Org = "376bb580-5008-5204-aca3-e3896c9df2c6",
  OrgSettings = "2db1c504-8c91-5fe5-9ab5-a28f6a8e56c3",
  PassiveDevice = "2a43feb3-3579-5063-b1e3-1c4729d53878",
  Provision = "c09df1a0-594e-5e46-bb6c-093ddfccc42a",
  RanManager = "f3bda4e0-71bf-52db-8a7a-e47007c4d82e",
  ReportsPage = "f97228e7-4786-5b0a-a9ea-c650b56ceafc",
  SIM = "c0823a7b-a43b-5554-9306-a4ca2bdd06ff",
  ServiceOrchestrationDashboard = "d583431f-2939-542f-8afa-fc5fda6687f6",
  Site = "0c714a70-fedf-5e3f-9e81-e31ac335b972",
  SoftwarePublisherDashboard = "71576584-3828-5ce2-a0b8-a4d0f39ce0e5",
  Subnet = "1c6cb076-19b8-5070-ba37-aa14b35b4485",
  SubnetMapping = "c49d0506-3908-5e04-856c-cdf38369584b",
  Troubleshooting = "a40f0258-159d-5799-bf5e-0b3be564872b",
  User = "38809d89-13ba-5f7a-93d2-17569e35f111",
}
