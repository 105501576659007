import { useReducer } from "react";

import { H1, H2 } from "@src/Components/Text";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { getLSItemWithMigration, useLocalStorageKey } from "@src/Hooks/localStorageKey";
import { localStorageReducer } from "@src/Reducers/localStorageReducer";
import {
  DesignFirstLine,
  DesignWrapper
} from "@src/SharedViews/ServiceDesigner/ServiceDesignerComponents";

import { BlockPublisher } from "./BlockPublisher";
import { publisherStorageKey } from "./publisherStorageKey";
import { BlockPublisherForm } from "./serialise";
import { TabBar } from "./TabBar";

export const emptyTemplateForm: BlockPublisherForm = {
  templateName: "",
  descriptor: {
    name: "",
    version: "",
    displayName: "",
    vendor: "",
    description: "",
    iconUrl: "",
    categories: []
  },
  values: "",
  overrides: "",
  selectedVersion: "",
  displayName: ""
};

export default function PublisherPage() {
  const key = useLocalStorageKey(publisherStorageKey);
  const orgId = useOrgCtx();

  if (!key) return null;

  return (
    <DesignWrapper>
      <DesignFirstLine>
        <H1>Publisher</H1>
        <H2>create chart from template</H2>
      </DesignFirstLine>
      <TabBar />
      <PublisherPageInner key={orgId} lsKey={key} />
    </DesignWrapper>
  );
}

function PublisherPageInner({ lsKey }: { lsKey: string }) {
  const value = getLSItemWithMigration(window.localStorage, lsKey, emptyTemplateForm);
  const [formValues, dispatch] = useReducer(localStorageReducer, {
    localStorageValue: value ? value : JSON.stringify(emptyTemplateForm),
    key: lsKey
  });

  if (!formValues.localStorageValue) return null;
  return (
    <BlockPublisher formValues={JSON.parse(formValues.localStorageValue)} dispatch={dispatch} />
  );
}
