import { Navigate, useLocation } from "react-router-dom";

import { useOrgCtx } from "@src/Hooks/Context/orgCtx";

import { cloudManagerRedirect } from "./cloudManagerRedirect";

// Redirect from old /mec routes into /cloud-manager routes
export function CloudManagerRedirect() {
  const { pathname } = useLocation();
  const org = useOrgCtx();

  if (!org) return <></>;
  const dest = cloudManagerRedirect(pathname, org);
  return <Navigate to={dest} replace />;
}
