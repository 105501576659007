import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const isProduction = process.env.NODE_ENV === "production";

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    debug: !isProduction,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
