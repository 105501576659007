import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/link-error";
import { Block, BlockChart, OktoResource, Org, Settings, User } from "@src/Generated/graphql";

interface NetworkError {
  statusCode: number;
}

function isNetworkError(error: unknown): error is NetworkError {
  return Number.isInteger((error as NetworkError).statusCode);
}

export function makeCache() {
  return new InMemoryCache({
    typePolicies: {
      Settings: {
        merge(existing = {}, incoming: Partial<Settings>, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        }
      },
      Block: {
        keyFields: ["id", "chainRevision"],
        fields: {
          resources: {
            merge(_ = [], incoming: Partial<OktoResource>[]) {
              return incoming;
            }
          }
        }
      },
      OktoResource: {
        keyFields: false
      },
      OrgRole: {
        keyFields: false
      },
      Org: {
        fields: {
          users: {
            merge(_ = [], incoming: Partial<User>[]) {
              return incoming;
            }
          }
        }
      },
      ServiceChain: {
        fields: {
          blocks: {
            merge(_ = [], incoming: Partial<Block>[]) {
              return incoming;
            }
          }
        }
      },
      User: {
        fields: {
          orgs: {
            merge(_ = [], incoming: Partial<Org>[]) {
              return incoming;
            }
          }
        }
      },
      Query: {
        fields: {
          blockCharts: {
            merge(_ = [], incoming: Partial<BlockChart>[]) {
              return incoming;
            }
          },
          users: {
            merge(_ = [], incoming: Partial<User>[]) {
              return incoming;
            }
          }
        }
      }
    }
  });
}

export const clients = new Map<string, ApolloClient<unknown>>();

function makeApolloClient(org?: string) {
  return new ApolloClient({
    cache: makeCache(),
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError, operation }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        }

        if (networkError && isNetworkError(networkError)) {
          console.log(`[Network error]: ${operation.operationName} - ${networkError}`);
        }
      }),
      createHttpLink({
        credentials: "same-origin",
        uri: "/query",
        headers: {
          "x-org": org || "*"
        }
      })
    ]),
    resolvers: {}
  });
}

const orglessApolloClient = makeApolloClient();

export function getApolloClient(org: string | null) {
  if (!org) return orglessApolloClient;

  let c = clients.get(org);
  if (!c) {
    c = makeApolloClient(org);
    clients.set(org, c);
  }

  return c;
}
