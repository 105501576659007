import * as Yup from "yup";

import { ValuesField } from "@src/Generated/graphql";

export type ValueType = string | boolean | number | object | undefined | null;

export interface ConfigField extends Omit<ValuesField, "value" | "__typename"> {
  value: ValueType;
  readonly initialValue: ValueType;
  open: boolean;
  selected: boolean;
}

export const siteFieldType = {
  label: "infraSiteId",
  type: "site-selector",
  weight: 102
};
export const coreFieldType = {
  label: "infraCoreId",
  type: "core-selector",
  weight: 101
};
export const locationFieldType = {
  label: "location",
  type: "location-selector",
  weight: 100
};

const specialFields = [locationFieldType, siteFieldType, coreFieldType];

const specialFieldsWeights = {
  name: 103,
  [locationFieldType.label]: locationFieldType.weight,
  [siteFieldType.label]: siteFieldType.weight,
  [coreFieldType.label]: coreFieldType.weight
};

interface InitConfigFieldParams {
  fields: Omit<ValuesField, "__typename">[];
  isEdit?: boolean;
  weightedSort?: boolean;
}

export function initFormConfigFields({
  fields = [],
  isEdit = false,
  weightedSort = false
}: InitConfigFieldParams): ConfigField[] {
  return (weightedSort ? [...fields].sort(configFieldsSort) : fields).map(field => {
    const { value, redacted, required } = field;
    return {
      ...field,
      type: getFieldType(field),
      value: getFieldValue(field, isEdit),
      initialValue: value,
      open: !isEdit || !redacted,
      selected: required && (!isEdit || !redacted)
    };
  });
}

function getFieldType(field: Omit<ValuesField, "__typename">) {
  const specialField = specialFields.find(f => f.label === field.label);
  return (specialField?.type || field.type || "").toLowerCase();
}
function getFieldValue(field: Omit<ValuesField, "__typename">, isEdit: boolean) {
  const type = getFieldType(field);
  const serialisedValue =
    type === "boolean"
      ? field.value === "true"
      : type === locationFieldType.type
      ? JSON.parse(field.value as string)
      : isEdit
      ? field.value || ""
      : "";

  return serialisedValue;
}

export function configFieldsSort(
  a: Omit<ValuesField, "__typename">,
  b: Omit<ValuesField, "__typename">
) {
  const weightA = specialFieldsWeights[a.label] ?? 0;
  const weightB = specialFieldsWeights[b.label] ?? 0;
  return weightB - weightA;
}

export function customValidation(value: ValueType, ctx: Yup.TestContext) {
  const { selected, required, label, type } = ctx.parent as ConfigField;
  if (!selected) return true;

  if (required && (value == null || value === "")) {
    return ctx.createError({ message: `${label} is a required field` });
  }

  if (type === "number" || type === "integer") {
    const invalidCharacters = ["e", "-", "+"];
    if (invalidCharacters.some(char => String(value).includes(char))) {
      return ctx.createError({ message: `${label} must be a valid non negative number` });
    }
  }

  return true;
}

export const yupConfigSchema = Yup.array()
  .of(
    Yup.object<ConfigField>().shape({
      label: Yup.string().required(),
      value: Yup.mixed().test("field-valid", customValidation),
      required: Yup.boolean(),
      redacted: Yup.boolean(),
      open: Yup.boolean(),
      selected: Yup.boolean(),
      initialValue: Yup.mixed().nullable()
    })
  )
  .required();
