import { Dashboard } from "./currentDashboard";

interface RoutingParams {
  org: string | null;
  subRoute?: string | null;
  site?: string | null;
  network?: string | null;
}

export interface DashboardObject {
  name: Dashboard;
  displayName: string;
  route: (params: RoutingParams) => string;
}

function getRoute({ org, subRoute }: RoutingParams, route: string) {
  if (!org) return "";
  return `/app/${org}/${route}` + (subRoute ? `/${subRoute}` : "");
}

export const serviceOrchestration = Object.freeze({
  name: "service-orchestration",
  displayName: "Service Orchestration",
  route: p => getRoute(p, "service-orchestration")
} as DashboardObject);

export const softwarePublisher = Object.freeze({
  name: "software-publisher",
  displayName: "Software Publisher",
  route: p => getRoute(p, "software-publisher")
} as DashboardObject);

export const cloudManager = Object.freeze({
  name: "cloud-manager",
  displayName: "Cloud Manager",
  route: p => getRoute(p, "cloud-manager")
} as DashboardObject);

export const tenantSettings = Object.freeze({
  name: "settings",
  displayName: "Tenant Settings",
  route: p => getRoute(p, "settings")
} as DashboardObject);

export const globalSettings = Object.freeze({
  name: "global-settings",
  displayName: "Global Settings",
  route: p => (p.org ? getRoute(p, "global-settings") : "global-settings")
} as DashboardObject);

export const landingPage = Object.freeze({
  name: "home",
  displayName: "Landing Page",
  route: p => getRoute(p, "home")
} as DashboardObject);

export const noDashboard = Object.freeze({
  name: "no-dashboard",
  displayName: "Dashboards",
  route: _ => ``
} as DashboardObject);

const mobileNetworksRoute = ({ org, subRoute, network }: RoutingParams, route: string) => {
  if (!org) return "";
  return (
    `/app/${org}/${route}` + `${network ? `/${network}` : ""}` + `${subRoute ? `/${subRoute}` : ""}`
  );
};
export const mobileNetworksDashboard = Object.freeze({
  name: "mobile-networks",
  displayName: "Mobile Networks",
  route: p => mobileNetworksRoute(p, "mobile-networks")
} as DashboardObject);

const infrastructureRoute = ({ org, subRoute, site }: RoutingParams, route: string) => {
  if (!org) return "";
  return (
    `/app/${org}/${route}` + `${site ? `/site/${site}` : ""}` + `${subRoute ? `/${subRoute}` : ""}`
  );
};
export const infrastructureDashboard = Object.freeze({
  name: "infrastructure",
  displayName: "Infrastructure Manager",
  route: p => infrastructureRoute(p, "infrastructure")
} as DashboardObject);
