import { useQuery } from "@apollo/client";
import { OrgNameQuery, OrgNameQueryVariables } from "@src/Generated/graphql";
import OrgName from "@src/GraphQLQueries/OrgName.graphql";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";

export function useOrgInfo(fetchName = false) {
  const orgCtx = useOrgCtx();

  const { data, loading } = useQuery<OrgNameQuery, OrgNameQueryVariables>(OrgName, {
    variables: { id: orgCtx as string },
    skip: !orgCtx || !fetchName
  });

  return {
    orgId: orgCtx,
    orgName: data?.org?.displayName,
    loading
  };
}
