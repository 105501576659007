import { ParameterButton, ValueStatusEditor } from "@src/Components/ChartSelector/ConfigStyles";
import { EditorStatus } from "@src/Components/EmbeddedEditor/editorStatus";

import { DesignerEditingSection, PublisherEditingSection } from "./editingSections";

type EditorSectionButton = {
  section: PublisherEditingSection | DesignerEditingSection;
  sectionName: PublisherEditingSection | DesignerEditingSection;
  setSection: (section: PublisherEditingSection | DesignerEditingSection) => void;
  editorState?: EditorStatus;
  titleLabel?: string;
};

export function EditorSectionButton({
  section,
  sectionName,
  setSection,
  editorState,
  titleLabel
}: EditorSectionButton) {
  return (
    <ParameterButton onClick={() => setSection(sectionName)} $selected={section === sectionName}>
      {sectionName}
      {editorState && editorState !== EditorStatus.Default && (
        <ValueStatusEditor
          $unsaved={editorState === EditorStatus.Unsaved}
          title={
            (editorState === EditorStatus.Saved ? "modified" : "unsaved changes to") +
            ` block ${titleLabel}`
          }
        >
          {editorState === EditorStatus.Saved && "M"}
          {editorState === EditorStatus.Unsaved && "⬤"}
        </ValueStatusEditor>
      )}
    </ParameterButton>
  );
}
