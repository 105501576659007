import ServiceDesignerIcon from "@img/project-diagram-solid.svg";
import ServicesIcon from "@img/services.svg";
import { NavIcons } from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { serviceOrchestration } from "@src/Hooks/Dashboard/dashboards";
import { useDefaultOrg } from "@src/Hooks/defaultOrg";
import { useTranslation } from "react-i18next";

export function ServicesNavbar() {
  const orgCtx = useOrgCtx();
  const { org: defaultOrg } = useDefaultOrg();
  const { t } = useTranslation("nav");

  const rootPath = serviceOrchestration.route({ org: orgCtx || defaultOrg || null });
  return (
    <>
      <NavIcons>
        <NavItem
          linkTo={rootPath + "/overview"}
          label={t("services.services")}
          icon={ServicesIcon}
        />
        <NavItem
          linkTo={rootPath + "/service-designer"}
          label={t("services.designer")}
          icon={ServiceDesignerIcon}
        />
      </NavIcons>
    </>
  );
}
