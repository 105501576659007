import styled from "styled-components";

interface ErrorMessageProps {
  error: string;
}

export const Error = styled.span`
  color: ${props => props.theme.error};
`;

export function ErrorMessage({ error }: ErrorMessageProps) {
  return <Error>{error}</Error>;
}

interface ErrorMessagesProps {
  errors: ReadonlyArray<Error>;
}

export function ErrorMessages({ errors }: ErrorMessagesProps) {
  return (
    <div>
      {errors.map((e, i) => (
        <p key={i}>
          <Error>{e.message}</Error>
        </p>
      ))}
    </div>
  );
}
