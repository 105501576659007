import { Navigate, useLocation } from "react-router-dom";

import { useOrgCtx } from "@src/Hooks/Context/orgCtx";

import { mobileNetworksRedirect } from "./mobileNetworksRedirect";

// Redirect from old /mpn route into the new /mobile-networks ones
export function MobileNetworksRedirect() {
  const { pathname } = useLocation();
  const org = useOrgCtx();

  if (!org) return <></>;
  const dest = mobileNetworksRedirect(pathname, org);
  return <Navigate to={dest} replace />;
}
