// Code generated by jtd-codegen for TypeScript v0.2.1

export enum Namespace {
  AdminDashboard = "AdminDashboard",
  BlockChart = "BlockChart",
  Chain = "Chain",
  ChartReleaseShell = "ChartReleaseShell",
  CloudResource = "CloudResource",
  Cloudlet = "Cloudlet",
  CloudletShell = "CloudletShell",
  ComputeResource = "ComputeResource",
  Core = "Core",
  CoreManager = "CoreManager",
  Dashboard = "Dashboard",
  Env = "Env",
  EnvBranding = "EnvBranding",
  FederationGuest = "FederationGuest",
  FederationHost = "FederationHost",
  GlobalGroup = "GlobalGroup",
  Gnb = "Gnb",
  Ippool = "IPPool",
  Logs = "Logs",
  MecChain = "MecChain",
  MecSite = "MecSite",
  Network = "Network",
  ObjectPolicy = "ObjectPolicy",
  Org = "Org",
  OrgGroup = "OrgGroup",
  OrgSettings = "OrgSettings",
  PassiveDevice = "PassiveDevice",
  Provision = "Provision",
  RanManager = "RanManager",
  Site = "Site",
  Subnet = "Subnet",
  Troubleshooting = "Troubleshooting",
  User = "User",
  UserObjectPolicy = "UserObjectPolicy",
  Chains = "chains",
  Dashboards = "dashboards",
  Migrations = "migrations",
  Orgs = "orgs",
}
