export enum EditedChart {
  Values = "values",
  Overrides = "overrides"
}
type ChangeValue = {
  chart: EditedChart;
  value: string;
  block: string;
};

type ChartSelector = {
  chart: EditedChart;
  block: string;
};

type BothValues = {
  values: string;
  overrides: string;
};

export type PublisherEditorAction =
  | { type: "change" | "unlock"; payload: ChangeValue }
  | { type: "cancel" | "save" | "loadOriginal"; payload: ChartSelector }
  | { type: "init" | "changeVersion"; payload: BothValues };
