import { useCallback } from "react";
import styled from "styled-components";

import { MarketplaceProps as ChartFieldFiltersProps } from "@src/Hooks/marketplace";
import { MarketplaceFilterType } from "@src/SharedViews/ServiceDesigner/Marketplace/marketplaceReducer";

import { FilterEntity } from "../Filters/filterEntity";
import { FiltersList } from "../Filters/FiltersList";

const Wrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export function ChartFieldFilters<T>({
  loading,
  filters,
  availableCategories,
  otherCategories,
  otherVendors,
  availableVendors,
  dispatch
}: ChartFieldFiltersProps<T>) {
  const categoryFilters = filters.filter(f => f.type === "category").map(({ value }) => value);
  const vendorFilters = filters.filter(f => f.type === "vendor").map(({ value }) => value);

  const selectFilter = useCallback(
    (filter: FilterEntity<MarketplaceFilterType, string>) => {
      dispatch({ type: "selectFilter", filter });
    },
    [dispatch]
  );

  const removeFilter = useCallback(
    (filter: FilterEntity<MarketplaceFilterType, string>) => {
      dispatch({ type: "removeFilter", filter });
    },
    [dispatch]
  );

  return (
    <Wrapper>
      <FiltersList
        title="categories"
        otherItems={otherCategories}
        availableItems={availableCategories}
        filters={categoryFilters}
        loading={loading}
        fieldFilter="category"
        selectFilter={selectFilter}
        removeFilter={removeFilter}
        getItemKey={getFilterKey}
        renderItem={renderFilter}
        checkSelected={checkSelected}
      />

      <FiltersList
        title="vendors"
        otherItems={otherVendors}
        availableItems={availableVendors}
        filters={vendorFilters}
        loading={loading}
        fieldFilter="vendor"
        selectFilter={selectFilter}
        removeFilter={removeFilter}
        getItemKey={getFilterKey}
        renderItem={renderFilter}
        checkSelected={checkSelected}
      />
    </Wrapper>
  );
}

function getFilterKey(item: string) {
  return item;
}

function renderFilter(item: string) {
  return item;
}

function checkSelected(filters: string[], item: string) {
  return filters.includes(item);
}
