import { useCallback, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { useFetchCoreList } from "@src/MPNDashboard/Infrastructure/coresRequests";
import { NetworkElement } from "@src/MPNDashboard/Infrastructure/NetworkElement";
import {
  InventoryResponse,
  serialiseNetworkResources
} from "@src/MPNDashboard/Infrastructure/serialiseNetworkResources";
import {
  NetworkResponse,
  SerialisedNetwork,
  serialiseNetworksData,
  useFetchNetworks
} from "@src/MPNDashboard/networks";

import { useLandingPage } from "./Hooks/Permissions/landingPage";

export function useQuickNavMenuState(): QuickNavMenuState {
  const { allowed: enabled } = useLandingPage();

  const [selectedNetwork, setSelectedNetwork] = useState<SerialisedNetwork>();

  const setInitialNetwork = useCallback((data: NetworkResponse[]) => {
    const fetchedNetworks = (serialiseNetworksData(data) as SerialisedNetwork[]).sort(
      (netA, netB) => netA.displayname.localeCompare(netB.displayname)
    );
    setSelectedNetwork(fetchedNetworks.at(-1));
  }, []);

  const { networks, loading: loadingNetworks } = useFetchNetworks(
    ["quick-networks"],
    { staleTime: 0, enabled: !!enabled },
    {
      onComplete: setInitialNetwork
    }
  );

  const [selectedCore, setSelectedCore] = useState<NetworkElement>();

  const setInitialCore = useCallback((data: InventoryResponse[]) => {
    const fetchedCores = (serialiseNetworkResources(data) as NetworkElement[]).sort((netA, netB) =>
      netA.displayname.localeCompare(netB.displayname)
    );
    setSelectedCore(fetchedCores.at(-1));
  }, []);

  const { data: cores, loading: loadingCores } = useFetchCoreList(
    ["quick-cores", selectedNetwork?.uid as string],
    { refetchOnWindowFocus: false, staleTime: 0, enabled: !!enabled && !!selectedNetwork?.uid },
    { onComplete: setInitialCore },
    selectedNetwork?.uid
  );

  return {
    networks,
    loadingNetworks,
    selectedNetwork: selectedNetwork ?? null,
    setSelectedNetwork,
    cores,
    loadingCores,
    selectedCore: selectedCore ?? null,
    setSelectedCore
  };
}

export interface QuickNavMenuState {
  networks: SerialisedNetwork[];
  loadingNetworks: boolean;
  selectedNetwork: SerialisedNetwork | null;
  setSelectedNetwork: (network: SerialisedNetwork) => void;
  cores: NetworkElement[];
  loadingCores: boolean;
  selectedCore: NetworkElement | null;
  setSelectedCore: (core: NetworkElement) => void;
}

export function useLandingState() {
  return useOutletContext<QuickNavMenuState>();
}
