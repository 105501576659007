import { Namespace } from "@src/Generated/permissions/namespace";
import { Permit } from "@src/Generated/permissions/permit";

import { usePermissionCheck } from "../permissionsCheck";

export function useCanViewConfigManagementEntity() {
  return usePermissionCheck({
    objectType: Namespace.ObjectPolicy,
    objectId: CONFIGURATION_MANAGEMENT_OBJECT_POLICY_ID,
    relation: Permit.Read
  });
}

export function useCanCreateConfigManagementEntity(coreId: string | null) {
  return usePermissionCheck({
    objectType: Namespace.Core,
    objectId: coreId,
    relation: Permit.CreateConfigurationManagement
  });
}

export function useCanEditConfigManagementEntity(coreId: string | null) {
  return usePermissionCheck({
    objectType: Namespace.Core,
    objectId: coreId,
    relation: Permit.WriteConfigurationManagement
  });
}

export function useCanDeleteConfigManagementEntity(coreId: string | null) {
  return usePermissionCheck({
    objectType: Namespace.Core,
    objectId: coreId,
    relation: Permit.DeleteConfigurationManagement
  });
}
